import { BaseService } from "../../../services/BaseService"
import { petsStore } from "../store/petsStore"
import { IBuyPetResponse, IPet, IPetsStore, IReward, IRewardAction, IUserPet, PetBuyInfo, PetSellInfo } from "../types"
import { $api } from "../../../services/interceptor"
import { userService } from "../../../services/user/service"
import { getErrorMessage } from "../../../utils/getErrorMessage"
import { userStore } from "../../../services/user/store"

class PetsAPI extends BaseService<IPetsStore> {
	constructor() {
		super(petsStore)
	}

	async getPets() {
		try {
			this.setState({ loading: true })
			const res = await $api.get<IPet[]>("/pets")
			const pets = res.data
			this.setState({ pets })
		} catch (e) {
		} finally {
			this.setState({ loading: false })
		}
	}

	async changePetName({ customName, userPetId }: { customName: string; userPetId: string }) {
		try {
			this.setState({ changingPet: true })
			await $api.patch<IPet[]>(`/pets/user-pets/${userPetId}`, { customName })
			await this.updateInfo()
			return true
		} catch (e) {
			return false
		} finally {
			this.setState({ changingPet: false })
		}
	}

	async buyPet({ petId }: { petId: string }) {
		try {
			this.setState({ changingPet: true })
			const res = await $api.post<IBuyPetResponse>(`/pets/${petId}/buy`, {})

			const userPetId = res.data.id
			await this.updateInfo()

			if (userStore.getState().user?.pet?.id !== userPetId) {
				await this.changePet({ userPetId })
			}

			return userPetId
		} catch (e) {
			throw e
		} finally {
			this.setState({ changingPet: false })
		}
	}

	async changePet({ userPetId }: { userPetId: string }) {
		try {
			this.setState({ changingPet: true })
			await $api.post<IPet[]>(`/pets/user-pets/${userPetId}/select`, {})
			await this.getPets()
			this.closePetsInfoModal()

			await userService.getUserData()
			await this.getDailyRewards()

			return true
		} catch (e) {
			return false
		} finally {
			this.setState({ changingPet: false })
		}
	}

	async choosePet(petID: string) {
		try {
			const userPetID = await this.buyPet({ petId: petID })

			if (userPetID) {
				await this.changePet({ userPetId: userPetID })
			}

			return true
		} catch (e) {
			throw e
		} finally {
			this.setState({ changingPet: false })
		}
	}

	async sellPet({ userPetId }: { userPetId: string }) {
		try {
			this.setState({ changingPet: true })
			await $api.post<IPet[]>(`/pets/user-pets/${userPetId}/sell`, {})
			this.closePetsInfoModal()

			this.updateInfo()

			return true
		} catch (e) {
			throw e
		} finally {
			this.setState({ changingPet: false })
		}
	}

	async claimReward() {
		try {
			this.setState({ claiming: true })
			await $api.post<IPet[]>(`/daily-reward/complete`, {})
			this.getRewards()
			this.getGlobalStreak()
			this.getRewardsActions()
			userService.getUserData()
			return true
		} catch (e) {
			return false
		} finally {
			this.setState({ claiming: false })
		}
	}

	async getRewards() {
		try {
			const res = await $api.get<IReward>(`/daily-reward/current-progress`)
			this.setState({ reward: res.data || undefined })
		} catch (e) {
			console.log(e)
		}
	}

	async getGlobalStreak() {
		try {
			const res = await $api.get<{ streak: number }>(`/daily-reward/global-streak`, {})
			this.setState({ globalStreak: res.data.streak })
		} catch (e) {
			console.log(e)
		}
	}

	async getRewardsActions() {
		try {
			const res = await $api.get<IRewardAction[]>(`/daily-reward/actions`, {})
			this.setState({ rewardAction: res.data })
		} catch (e) {
			console.log(e)
		}
	}

	getDailyRewards = async () => {
		await Promise.allSettled([await this.getRewards(), await this.getGlobalStreak(), await this.getRewardsActions()])
	}

	async levelUp(userPetId: string) {
		this.setState({ isLevelUpLoading: true })

		try {
			await $api.post<IUserPet>(`/pets/user-pets/${userPetId}/level-up`)
			await this.getRewardsActions()

			this.closePetsInfoModal()
			userService.getUserData()
			this.getPets()
		} catch (e) {
			this.setLevelUpError(getErrorMessage(e))
		} finally {
			this.setState({ isLevelUpLoading: false })
		}
	}

	evolvePet = async (userPetId: string, successCallback?: () => void) => {
		try {
			await $api.post<IUserPet>(`/pets/user-pets/${userPetId}/upgrade`)
			this.closePetsInfoModal()

			this.updateInfo()

			successCallback && successCallback()
		} catch (e) {
			this.setEvolveError(getErrorMessage(e))
		}
	}

	setEvolveError = (error: string) => {
		this.setState({ evolveError: error })
	}

	setLevelUpError = (error: string) => {
		this.setState({ levelUpError: error })

		setTimeout(() => {
			this.setState({ levelUpError: "" })
		}, 3000)
	}

	openPetsInfoModal = () => {
		this.setState({ isPetInfoModalOpened: true })
	}

	closePetsInfoModal = () => {
		this.setState({ isPetInfoModalOpened: false })
	}

	setIsProgressionModalOpened = (value: boolean) => {
		this.setState({ isProgressionModalOpened: value })
	}
	setIsEvolutionModalOpened = (value: boolean) => {
		this.setState({ isEvolutionModalOpened: value })
	}
	setIsPetsModalOpen = (value: boolean) => {
		this.setState({ isPetsModalOpen: value })
	}
	setSelectedPet = (value: IPet | null) => {
		this.setState({ selectedPet: value })
	}
	setBuyPetInfo = (value: PetBuyInfo | null) => {
		this.setState({ buyPetInfo: value })
	}
	setSellPetInfo = (value: PetSellInfo | null) => {
		this.setState({ sellPetInfo: value })
	}
	setSellError = (value: string) => {
		this.setState({ sellError: value })
		setTimeout(() => {
			this.setState({ sellError: "" })
		}, 3000)
	}
	setBuyError = (value: string) => {
		this.setState({ buyError: value })
		setTimeout(() => {
			this.setState({ buyError: "" })
		}, 3000)
	}

	private async updateInfo() {
		await this.getPets()

		await userService.getUserData()

		await this.getDailyRewards()
	}
}

export const petsService = new PetsAPI()
