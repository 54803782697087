import {FormEvent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../../components/Buttons/Button";
import {OTPCodeInput} from "../../../../login/components/OTPCodeInput/OTPCodeInput";
import accountLinkingService from "../../../../login/services/accountLinkingService";
import {useAccountLinkingStore} from "../../../../login/store/accountLinkingStore";
import {useLoginStore} from "../../../../login/store/loginStore";
import rootModalCss from "../MainModalComponent/ConnectEmailMainModalWrapper.module.scss";

const MAX_OTP_SYMBOLS = 6;

export const AccountLinkingStep2 = () => {

  const {t} = useTranslation();

  const {
    email,
    connectAccountLoading
  } = useAccountLinkingStore();

  const {otpCode, otpError} = useLoginStore()

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await accountLinkingService.linkEmailAccount(email, otpCode, false);
  };

  useEffect(() => {
    if (otpCode.length === MAX_OTP_SYMBOLS) {
      accountLinkingService.linkEmailAccount(email, otpCode, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpCode]);


  const isSubmitDisabled = otpCode.length < MAX_OTP_SYMBOLS || Boolean(otpError);

  return <form
    id='form-step-2'
    onSubmit={!isSubmitDisabled ? onSubmit : undefined}
  >

    <OTPCodeInput className={rootModalCss.customOtpCodeInput}/>

    <div className="account-email-linking-modal__buttons">
      <Button
        onClick={accountLinkingService.accountLinkingStepBackFromOtp}
        isLoading={connectAccountLoading}
        size="large"
        isUppercased
      >
        {t("auth.linkAccount.step2.button.back")}
      </Button>
      <Button
        form="form-step-2"
        type="submit"
        disabled={isSubmitDisabled}
        isLoading={connectAccountLoading}
        variant="secondary"
        size="large"
        isUppercased
        onClick={accountLinkingService.accountLinkingStepNext}
      >
        {t("auth.linkAccount.step2.button.verify")}
      </Button>
    </div>
  </form>
}
