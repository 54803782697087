import React, { useEffect, useState } from "react"
import { useShallow } from "zustand/react/shallow"
import { useTranslation } from "react-i18next"

import Input from "../../../../components/Inputs"
import { Button } from "../../../../components/Buttons/Button"
import { EditIcon } from "../../../../assets/iconComponents/new_design_icons/EditIcon"
import { TextArea } from "../../../../components/TextArea"
import { SocialEdit } from "./SocialEdit"
import { PointsLabel } from "./PointsLabel"
import { IEditAvatarPayload, SocialName } from "../../../../services/user/types"
import { PointActions } from "../../../../services/actionPoints/types"
import Checkbox from "../../../../components/Checkbox"
import ElementWithIcon from "../../../../components/Buttons/ElementWithIcon/ElementWithIcon"
import { ModalWithHeader } from "../../../../components/Modals/ModalWithHeader"

import { parseAxiosError } from "../../../../utils/errors"
import { useActionPointsStore } from "../../../../services/actionPoints/store"
import { getConnectedSocial } from "../../../../utils/getConnectedSocial"
import { getFontClass } from "../../../../utils/getFontClass"
import { getDiscordLoginLink } from "../../../../configs/discord.config"
import { getIsActionPointsRecieved } from "../../../../utils/getIsActionPointsRecieved"
import { getDefaultEditInfo } from "../../helpers"
import { userStore } from "../../../../services/user/store"
import { userService } from "../../../../services/user/service"
import accountLinkingService from "../../../login/services/accountLinkingService"
import cn from "classnames"

import { TWITTER_LOGIN_LINK } from "../../../../configs/twitter.config"

import css from "./styles.module.scss"

interface PropsTypes {
	isOpen: boolean
	handleClose: () => void
	loading?: boolean
}

export interface TelegramAuthData {
	id: number
	first_name: string
	auth_date: number
	hash: string
	last_name?: string
	photo_url?: string
	username?: string
}

const handleConnectTwitter = () => {
	window.open(TWITTER_LOGIN_LINK, "_self")
}

const handleDiscordConnect = () => {
	window.open(getDiscordLoginLink(), "_self")
}

export const EditProfileModal = ({ isOpen, handleClose, loading }: PropsTypes) => {
	const user = userStore(useShallow(({ user }) => user))

	const { actionPoints } = useActionPointsStore()

	const [userInfo, setUserInfo] = useState<IEditAvatarPayload>(getDefaultEditInfo(user))

	const { t, i18n } = useTranslation()

	const [isModified, setIsModified] = useState(false)
	const [usernameError, setUsernameError] = useState("")

	const userSocialError = userStore(useShallow(({ socialError }) => socialError))
	const [editProfileErrors, setEditProfileErrors] = useState<string[]>([])

	useEffect(() => {
		setUserInfo(getDefaultEditInfo(user))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	useEffect(() => {
		const defaultUserInfo = getDefaultEditInfo(user)

		if (defaultUserInfo) {
			const isModified = JSON.stringify(defaultUserInfo) !== JSON.stringify(userInfo)
			setIsModified(isModified)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { id, value } = e.target

		if (id === "edit-username" && value.length > 50) {
			setUsernameError("Username cannot exceed 50 characters")
		} else if (id === "edit-username" && !value.trim().length) {
			setUsernameError("Username cannot be empty")
		} else {
			setUsernameError("")
		}

		setUserInfo(prev => ({
			...prev,
			updateProfileOptions: {
				...prev.updateProfileOptions,
				[id.split("-")[1]]: value,
			},
		}))

		setIsModified(true)

		setEditProfileErrors(currentErrors => currentErrors.filter(error => !error.includes(e.target.id.split("-")[1])))
	}

	const isTelegramConnected = !!getConnectedSocial(user, SocialName.Telegram)

	useEffect(() => {
		if (!isTelegramConnected) {
			const script = document.createElement("script")
			script.src = "https://telegram.org/js/telegram-widget.js"
			script.async = true

			document.body.appendChild(script)

			return () => {
				document.body.removeChild(script)
			}
		}

		return () => {}
	}, [isTelegramConnected])

	const handleTelegramConnect = () => {
		const origin = process.env.REACT_APP_TELEGRAM_CONNECTION_URL

		if (!isTelegramConnected) {
			window.open(origin, "Telegram", "popup,width=350,height=250")
		}
	}

	const onEdit = async () => {
		try {
			const isEdited = await userService.editProfile({
				payload: userInfo,
				userId: user?.id || "",
			})
			isEdited && handleClose()
		} catch (error) {
			const parsedError = parseAxiosError(error)
			setEditProfileErrors(parsedError)
		}
	}

	const inputErrorCondition = (field: string) => {
		const formattedField = field.charAt(0).toUpperCase() + field.slice(1)

		const error = editProfileErrors.find(error => error.toLowerCase().includes(field.toLowerCase()))
		return error ? `Enter ${formattedField} Link` : ""
	}

	const socialErrorCondition = (field: string) => {
		return userSocialError.toLowerCase().includes(field.toLowerCase()) ? userSocialError : ""
	}

	const onSocialNetworksVisibilityChange = (social: SocialName | "Email", isShown: boolean) => {
		setUserInfo(state => ({
			...state,
			updatePrivacySettingsOptions: {
				...state.updatePrivacySettingsOptions,
				["show" + social]: isShown,
			},
		}))
	}

	const isEmailVisible = userService.getSocialVisibility("Email")

	const bioLettersLeft = 200 - userInfo.updateProfileOptions.biography.length

	const isNotEmailAccount = !user?.email

	return (
		<ModalWithHeader
			open={isOpen}
			onClose={handleClose}
			title={t("profile.info.title")}
			bodyClassName={css.modalContentWrapperPdRight}
		>
			<div className={`${css.editProfileWrapper} custom-scrollbar-gray`}>
				<div className={css.twoEditInputsWrapper}>
					<div className={css.inputWrapper}>
						<p className={css.inputLabel}>
							<div className={cn(css.labelWithPoints, css.labelWithPoints_justifyed, getFontClass(i18n.language))}>
								<span>{t("profile.info.username")}</span>
								<PointsLabel
									amount={actionPoints?.ENTER_USERNAME}
									isShown={!getIsActionPointsRecieved(PointActions.ENTER_USERNAME, user)}
								/>
							</div>
						</p>
						<Input
							value={userInfo.updateProfileOptions.username}
							variant={"secondary"}
							id={"edit-username"}
							placeholder={"Enter your name"}
							onChange={handleInputChange}
							errorMessage={inputErrorCondition("username") || usernameError}
							className={css.input}
						/>
					</div>
				</div>
				<p className={css.inputLabel}>
					<div className={`${css.labelWithPoints} ${getFontClass(i18n.language)}`}>
						{t("profile.info.bio")}
						<PointsLabel
							amount={actionPoints?.ENTER_USERNAME}
							isShown={!getIsActionPointsRecieved(PointActions.ENTER_BIO, user)}
						/>
					</div>
					<div className={css.characters_wrapper}>
						<span className={css.text_characters}>{t("profile.info.characters")}:</span>
						<span className={css.text_characters_number}>{t("profile.info.characters-number")}:</span>
						<span className="opacity-[1]">{bioLettersLeft || 0}</span>
					</div>
				</p>
				<TextArea
					value={userInfo.updateProfileOptions.biography}
					variant={"secondary"}
					id={"edit-biography"}
					placeholder={t("profile.info.bio.placeholder")}
					onChange={handleInputChange}
					errorMessage={inputErrorCondition("biography")}
					maxLength={200}
					textAreaClassName="custom-scrollbar-gray"
					resize={false}
				/>
				<div className={css.assignedSocials}>
					<h3 className={cn(css.assignedSocialsTitle, getFontClass(i18n.language))}>
						{t("profile.info.socials.title")}
					</h3>
					<div className={css.twoEditInputsWrapper}>
						<div className={css.inputWrapper}>
							<SocialEdit
								connectedName={
									getConnectedSocial(user, SocialName.Telegram)?.socialData?.username ||
									getConnectedSocial(user, SocialName.Telegram)?.socialData?.firstName
								}
								social={SocialName.Telegram}
								connectionReward={actionPoints?.CONNECT_DISCORD}
								handleConnect={handleTelegramConnect}
								isRewardRecieved={getIsActionPointsRecieved(PointActions.CONNECT_TELEGRAM, user)}
								onSocialNetworksVisibilityChange={onSocialNetworksVisibilityChange}
								error={socialErrorCondition("Telegram")}
								wrapperCalssName={css.assignedSocialsBtn}
							/>
						</div>
						<div className={css.inputWrapper}>
							<SocialEdit
								connectedName={getConnectedSocial(user, SocialName.Discord)?.socialData?.username}
								social={SocialName.Discord}
								connectionReward={actionPoints?.CONNECT_DISCORD}
								isRewardRecieved={getIsActionPointsRecieved(PointActions.CONNECT_DISCORD, user)}
								handleConnect={handleDiscordConnect}
								onSocialNetworksVisibilityChange={onSocialNetworksVisibilityChange}
								error={socialErrorCondition("Discord")}
								wrapperCalssName={css.assignedSocialsBtn}
							/>
						</div>
					</div>
					<div className={css.twoEditInputsWrapper}>
						<div className={css.inputWrapper} style={{ width: "100%" }}>
							<SocialEdit
								connectedName={getConnectedSocial(user, SocialName.Twitter)?.socialData?.username}
								social={SocialName.Twitter}
								connectionReward={actionPoints?.CONNECT_TWITTER}
								handleConnect={handleConnectTwitter}
								isRewardRecieved={getIsActionPointsRecieved(PointActions.CONNECT_TWITTER, user)}
								onSocialNetworksVisibilityChange={onSocialNetworksVisibilityChange}
								error={socialErrorCondition("Twitter")}
								wrapperCalssName={css.assignedSocialsBtn}
							/>
						</div>
						<div
							className={css.inputWrapper}
							onClick={() => {
								if (isNotEmailAccount) {
									handleClose()
									accountLinkingService.setIsAccountCreationModalOpen(true)
								}
							}}
						>
							<p className={cn(css.inputLabel, getFontClass(i18n.language))}>{t("profile.info.email")}</p>
							<Input
								disabled={!isNotEmailAccount}
								value={isNotEmailAccount ? t("profile.info.socials.connect") : userInfo.updateUserOptions.email}
								style={isNotEmailAccount ? { cursor: "pointer" } : {}}
								variant={"secondary"}
								placeholder={"Example@gamil.com"}
								id={"edit-email"}
								errorMessage={inputErrorCondition("email")}
								className={css.input}
								iconButton={isNotEmailAccount ? <ElementWithIcon Icon={EditIcon} /> : undefined}
							/>
							<div className={css.hideEmailCheckBoxWrapper}>
								<Checkbox
									checked={!isEmailVisible}
									label={`${t("profile.info.socials.hide")} Email`}
									onChange={isShown => onSocialNetworksVisibilityChange("Email", !isShown)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={css.btnsBlock}>
					<Button className={css.large_button} onClick={handleClose} isUppercased>
						{t("buttons.cancel")}
					</Button>
					<Button
						className={`${css.large_button} ${css.purple}`}
						disabled={loading || !isModified || !!usernameError}
						isLoading={loading}
						onClick={onEdit}
						isUppercased
					>
						{t("buttons.save")}
					</Button>
				</div>
			</div>
		</ModalWithHeader>
	)
}
