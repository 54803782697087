import {FC} from 'react';
import {IconGoogle} from "../../../../newComponents/Icons/IconGoogle";
import {useQueryParam} from "../../../../newHooks/useQueryParam";
import loginService from "../../services/loginService";
import './LoginSocial.scss';
import {LoginTelegram} from "./LoginTelegram";

interface ILoginSocial {
  isUserAgreed?: boolean;
}

export const LoginSocial: FC<ILoginSocial> = ({isUserAgreed}) => {
  const {paramValue: refCode} = useQueryParam('ref');

  return (
    <ul className="login-social">
      <li className="login-social__item">
        <button
          onClick={refCode
            ? () => loginService.referralGoogleLogin(refCode)
            : loginService.loginWithGoogle}
          className="login-social__button"
          disabled={!isUserAgreed}
        >
          <IconGoogle/>
        </button>
      </li>

      <li className="login-social__item">
        <LoginTelegram
          className="login-social__button"
          disabled={!isUserAgreed}
        />
      </li>
    </ul>
  );
};
