import { useEffect } from "react"
import { useCharactersStore } from "../../store"
import { useTonAddress } from "@tonconnect/ui-react"

import { userStore } from "../../../../services/user/store"
import { charactersService } from "../../service"

import { NftCollectionItem } from "../NftCollectionItem"
import { Loader } from "../../../../components/Loader"

import styles from "./NftCollectionsList.module.scss"

const NftCollectionsList = () => {
	const { userCollectionsAndNfts, isUserNftsLoading } = useCharactersStore()
	const wallet = useTonAddress()
	const userId = userStore(state => state.user?.id)

	useEffect(() => {
		charactersService.getActiveCollection()
	}, [])

	useEffect(() => {
		if (userId && wallet) {
			charactersService.getUserNfts(wallet)
		}
	}, [userId, wallet])

	return (
		<div className={styles.borderGradientContainer}>
			<div className={styles.wrapper}>
				<div className={`${styles.scrollContainer} custom-scrollbar-gray`}>
					{(userCollectionsAndNfts || []).map(collection => (
						<NftCollectionItem
							key={collection.collection?.id}
							collection={collection.collection}
							nftsCount={collection.claimsData?.claims.length || 0}
						/>
					))}
					{isUserNftsLoading ? <Loader /> : null}
				</div>
			</div>
		</div>
	)
}

export default NftCollectionsList
