import { MouseEvent, useCallback, useRef, useState } from "react"
import { useIsMobileResolution } from "./useIsMobileResolution"

export const useToggleSidebar = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	const sidebarRef = useRef<HTMLDivElement>(null)
	const isSidebarSlidable = useIsMobileResolution(980)

	const toggleSidebar = useCallback(
		(value?: boolean) => {
			if (isSidebarSlidable) {
				if (value === undefined) {
					setIsSidebarOpen(currentState => !currentState)
				} else {
					setIsSidebarOpen(value)
				}
			}
		},
		[setIsSidebarOpen, isSidebarSlidable],
	)

	const handleClickOutsideSidebar = (event: MouseEvent<HTMLDivElement>) => {
		if (isSidebarSlidable) {
			if (sidebarRef.current && sidebarRef.current.contains(event.target as Node)) {
				return
			}

			setIsSidebarOpen(false)
		}
	}

	return {
		sidebarRef,
		isSidebarOpen,
		toggleSidebar,
		handleClickOutsideSidebar,
	}
}
