import "./ClaimHeader.scss";
import {ErrorIcon} from "../../../../assets/iconComponents/Error";
import {FC} from "react";
import {useTranslation} from "react-i18next";

interface IClaimHeaderProps {
  title: string,
  status?: string,
  address?: string,
  isError?: boolean,
}

const ClaimHeader: FC<IClaimHeaderProps> = ({title, status, address, isError}) => {
  const {t} = useTranslation();

  return <header className="claim-header">
    <h3 className="claim-header__title">{title}</h3>
    <div className="claim-header__content">
      {status && <p className="claim-header__wallet-status">{status}</p>}
      {address && <p className="claim-header__wallet-address">{address}</p>}

      {isError && (
        <p className="claim-header__error">
          <ErrorIcon/>
          {t("claim.wrong-network")}
        </p>
      )}
    </div>
  </header>
}

export default ClaimHeader;
