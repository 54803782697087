import { localStorageKeys } from "../../constants/localStorageKeys"

const setRefCodeToLocalStorage = (refCode: string) => {
	localStorage.setItem(localStorageKeys.referrerCode, refCode)
}

const getRefCodeFromLocalStorage = () => {
	return localStorage.getItem(localStorageKeys.referrerCode)
}

const removeRefCodeFromLocalStorage = () => {
	localStorage.removeItem(localStorageKeys.referrerCode)
}

export const refCodeHelper = { setRefCodeToLocalStorage, removeRefCodeFromLocalStorage, getRefCodeFromLocalStorage }
