import { SVGProps } from "react"

const IconPlus = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x="6.85693" width="2.28571" height="16" rx="1.14286" fill="currentColor" />
		<rect y="9.14282" width="2.28571" height="16" rx="1.14286" transform="rotate(-90 0 9.14282)" fill="currentColor" />
	</svg>
)

export default IconPlus
