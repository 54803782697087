import {Trans, useTranslation} from "react-i18next";
import {Button} from "../../../../../../components/Buttons/Button";
import css from "./ConfirmationAccountStep.module.scss";
import avatarPlaceholder from "../../../../../../assets/images/AvatarPlaceholder.png";
import attentionIcon from "../../../../../../assets/icons/attention-icon.svg";
import {SwitchToggle} from "../../../../../../newComponents/SwitchToggle/SwitchToggle";
import {useMemo, useState} from "react";
import accountLinkingService from "../../../../../login/services/accountLinkingService";
import {useAccountLinkingStore} from "../../../../../login/store/accountLinkingStore";
import {useUserStore} from "../../../../../../services/user/store";

export const ConfirmationAccountStep = () => {
  const {t} = useTranslation()
  const [confirmationChecked, setConfirmationChecked] = useState(false)
  const {selectedAccountToLink, accountToLinking, email} = useAccountLinkingStore()
  const {user} = useUserStore()

  const {name, social, avatar} = useMemo(() => {
    if (accountToLinking !== null && "availableAccount" in accountToLinking) {
      const accountToLink = accountToLinking?.availableAccount
      return {
        name: (selectedAccountToLink === "email" ?
          accountToLink?.username :
          user?.profile.username) || "Not defined",
        social: selectedAccountToLink === "email" ?
          accountToLink?.email :
          `@${(user?.connectedSocials.find(social => social.social.name === "Telegram")?.socialData.username || "noname")}`,
        avatar: selectedAccountToLink === "email" ?
          accountToLink.avatarUrl || undefined :
          user?.avatar?.avatar?.imageUrl
      }
    }
    return {
      name: "",
      social: "",
      avatar: ""
    }

  }, [selectedAccountToLink, accountToLinking, user])

  return <div className={css.container}>
    <div className={css.avatar}>
      <div>
        <div className={css.avatarPlaceholder}>
          <div className={css.shine}/>
          <div className={css.img} style={{backgroundImage: `url(${avatar || avatarPlaceholder})`}}/>
        </div>
        <div className={css.title}>
          <p>{name}</p>
          <p>{social}</p>
        </div>
      </div>
    </div>
    <div className={css.notice}>
      <div>
        <img src={attentionIcon} alt="question-icon"/>
        <p>
          <Trans
            i18nKey={"auth.modal.accountCreation.exist.confirm.notice"}
            components={{
              purple: <span/>
            }}
          />
        </p>
      </div>
    </div>

    <SwitchToggle
      className={css.checkbox}
      checked={confirmationChecked}
      onChange={setConfirmationChecked}
      label={t("auth.modal.accountCreation.exist.confirm.checkbox")}
    />

    <div className={css.buttons}>
      <Button
        onClick={() => accountLinkingService.setState({accountCreationExistSteps: "choose"})}
        size="large"
        isUppercased
      >{t("auth.linkAccount.step2.button.back")}</Button>
      <Button
        onClick={() => accountLinkingService.linkExistingAccountToStepCongrats(email)}
        variant="secondary"
        size="large"
        disabled={!confirmationChecked}
        isUppercased
      >{t("auth.modal.accountCreation.exist.confirm.button")}</Button>
    </div>
  </div>
}
