import { useEffect, useRef, useState } from "react";
import css from "../styles.module.scss";
import { timer } from "../../../../../utils/formatDate";
import { SandClock } from "../../../../../assets/iconComponents/SandClock";
import { StackingTimerGradient } from "../../../../../assets/iconComponents/StackingTimerGradient";
import { useIsMobileResolution } from "../../../../../hooks/useIsMobileResolution";

interface PropsTypes {
  date: number;
  setIsWithoutPenalty?: (condition: boolean) => void;
  untilPenaltyDate: number;
  isHistoryMode?: boolean;
}
//isUnstaked && untilPenaltyDate && Date.now() <= +untilPenaltyDate

export const StackingTimer = ({
  date,
  setIsWithoutPenalty,
  untilPenaltyDate,
  isHistoryMode,
}: PropsTypes) => {
  const [tickTimer, setTickTimer] = useState([
    { label: "d", value: "00" },
    { label: "h", value: "00" },
    { label: "m", value: "00" },
  ]);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const isMobile = useIsMobileResolution();

  useEffect(() => {
    // @ts-ignore
    intervalIdRef.current = setInterval(() => {
      const timerDate = timer(new Date(untilPenaltyDate), true);
      if (Array.isArray(timerDate)) {
        setTickTimer(timerDate);
      }
      setIsWithoutPenalty &&
        setIsWithoutPenalty(Date.now() <= +untilPenaltyDate);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={css.timerWrapper}>
      <StackingTimerGradient className={css.timerGradient} />
      <div className={css.timerText}>
        <SandClock />
        {isHistoryMode ? (
          <p>Until Next Raffle:</p>
        ) : (
          <p>
            <b>Until 0%</b> <span>penalty</span>
          </p>
        )}
      </div>
      <ul className={css.clockItems}>
        {tickTimer.map((item, i, array) => (
          <li key={i + item.label} className={css.clockItem}>
            <span className={css.clockValue}>{item.value}</span>
            {!isMobile && <span className={css.clockLabel}>{item.label}</span>}
            {i !== array.length - 1 && <span className={css.clockDots}>:</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};
