import { useEffect, useMemo } from "react"
import { useShallow } from "zustand/react/shallow"
import { avatarsStore, useAvatarsStore } from "./components/Avatar/AvatarSelection/store"

import { userStore } from "../../services/user/store"
import avatarsService from "./components/Avatar/AvatarSelection/service"
import { userProfileStore } from "./store/store"
import { userProfileService } from "./service/service"
import accountLinkingService from "../login/services/accountLinkingService"

import { ProfileAvatar } from "./components/Avatar"
import { ProfileInfo } from "./components/Info"
import { EditModalBody } from "./components/Avatar/AvatarSelection"
import { InformationCard } from "./components/Avatar/AvatarSelection/InformationCard"
import { IsHaveEmailAccountModal } from "./components/modals/IsHaveEmailAccountModal/IsHaveEmailAccountModal"
import { AccountLinkingModal } from "./components/modals/AccountLinkingModal/AccountLinkingModal"
import { AccountCreationModal } from "./components/modals/AccountCreationModal/AccountCreationModal"

import { localStorageKeys } from "../../constants/localStorageKeys"

import styles from "./styles/styles.module.scss"

export const UserProfile = () => {
	const currentAvatar = userStore(useShallow(({ user }) => user?.avatar?.avatar?.id || ""))
	const { avatars } = useAvatarsStore()
	const { isEditAvatar, selectedAvatarId, selectedAvatarToEditId } = userProfileStore()

	const selectedAvatar = useMemo(() => {
		return avatars.find(({ id }) => id === selectedAvatarId)
	}, [selectedAvatarId, avatars])

	useEffect(() => {
		const isNewTgAccount = localStorage.getItem(localStorageKeys.isNewTgAccount)
		if (isNewTgAccount) {
			if (JSON.parse(isNewTgAccount)) {
				accountLinkingService.setIsNewRegistrationWithTelegram(true)
			}
		}
		avatarsService.getAvatars(() => {
			if (!selectedAvatarId) {
				userProfileService.setSelectedAvatarId(avatarsStore.getState()?.avatars?.at(0)?.id || "")
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleOpen = () => {
		userProfileService.setIsEditAvatar(true)
	}

	const handleClose = (isDataUpdateNeeded: boolean = false) => {
		userProfileService.setIsEditAvatar(false)
		if (isDataUpdateNeeded) {
			avatarsService.getAvatars(() => {
				if (!selectedAvatarId) {
					userProfileService.setSelectedAvatarId(avatarsStore.getState()?.avatars?.at(0)?.id || "")
				}
			})
		}
	}

	return (
		<div className={styles.wrapper}>
			{isEditAvatar ? (
				<InformationCard
					avatar={selectedAvatar}
					isChoosen={
						selectedAvatarToEditId === selectedAvatar?.id ||
						(!selectedAvatarToEditId && currentAvatar === selectedAvatar?.id)
					}
					isBought={!!selectedAvatar?.userAvatar}
				/>
			) : (
				<ProfileInfo />
			)}
			{isEditAvatar ? <EditModalBody handleClose={handleClose} /> : <ProfileAvatar handleOpen={handleOpen} />}
			<IsHaveEmailAccountModal />
			<AccountLinkingModal />
			<AccountCreationModal />
		</div>
	)
}
