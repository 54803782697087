import { Abi } from "viem"
import { APP_CHAIN } from "../../../staking/constants";
import vestingAbi from './vestingAbi.json';

const claimTestnet = "0x2Cd1b8f455aA6387038e7a15b8A57ea87f9596e7";
const claimMainnet = "0x61032448676BE34eC82FF7F94C7Ce3aDc455F122"

type IClaimContractData = {
  abi: unknown[] | Abi,
  address: `0x${string}`,
}

export const claimContractData: IClaimContractData = {
  abi: vestingAbi,
  address: !APP_CHAIN.testnet ? claimMainnet : claimTestnet
}



