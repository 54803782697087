import React, { FC, HTMLAttributes } from 'react';
import { NavLink } from "react-router-dom";
import { PV_DOCS_HREF, PV_GP_MARKET } from "../../sidebar.constants";
import cn from "classnames";
import "./Info.scss";
import { useTranslation } from "react-i18next";


interface IInfoProps extends HTMLAttributes<HTMLUListElement> { }


const Info: FC<IInfoProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <ul className={cn("info", className)}>
      <li className="info__item">
        <NavLink to={PV_DOCS_HREF} target="_blank" rel="noreferrer">{t("sidebar.doc")}</NavLink>
      </li>
      {/*<li className="info__item">*/}
      {/*  <NavLink to={PV_GP_MARKET} target="_blank" rel="noreferrer">{t("sidebar.demo")}</NavLink>*/}
      {/*</li>*/}
    </ul>
  );
};


export default Info;
