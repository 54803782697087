import { NFTRarity, RarityType } from "../../types"
import { RarityChip } from "./RarityChip"

import checkIcon from "../../../../assets/icons/check_cirle_black_bordered.svg"
import avatarPlaceholderGreen from "../../../../assets/images/nft/EarlyPlaceholderGreen.png"
import avatarPlaceholderBlue from "../../../../assets/images/nft/EarlyPlaceholderBlue.png"
import unknownNft from "../../../../assets/images/nft/unknown_nft.png"
import css from "./EarlyNftCard.module.scss"

type IEarlyNftCardProps = {
	avatarUrl?: string
	title: string
	rarity?: NFTRarity
	className?: string
	avatarType: "dashboard" | "bot" | "puma"
}

export const EarlyNftCard: React.FC<IEarlyNftCardProps> = ({ avatarUrl, title, rarity, className, avatarType }) => {
	const placeholderUrl =
		avatarType === "puma" ? unknownNft : avatarType === "dashboard" ? avatarPlaceholderBlue : avatarPlaceholderGreen
	return (
		<div className={`${css.wrapper} ${rarity ? css.wrapper_withChip : ""} ${className || ""}`}>
			<div className={css.wrapper_card}>
				{avatarUrl ? <img className={css.wrapper_card_check} src={checkIcon} alt="check_icon" /> : null}
				<div
					className={css.wrapper_card_img}
					style={{
						backgroundImage: `url(${avatarUrl || placeholderUrl})`,
					}}
				/>
				{rarity ? <RarityChip rarity={RarityType[rarity]} /> : null}
			</div>
			<div className={css.wrapper_title}>{title}</div>
		</div>
	)
}
