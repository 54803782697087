import { FormEvent, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { useAccountLinkingStore } from "../../../../login/store/accountLinkingStore"

import accountLinkingService from "../../../../login/services/accountLinkingService"

import { Button } from "../../../../../components/Buttons/Button"
import { InputField, InputFieldErrors } from "../../../../../newComponents/Input"
import { useLoginStore } from "../../../../login/store/loginStore"

export const AccountCreationStepEmail = () => {
	const { t } = useTranslation()

	const { email, emailErrorMessage, otpRequestLoading } = useAccountLinkingStore()
	const { otpError } = useLoginStore()

	const [submitDisabled, setSubmitDisabled] = useState(false)
	const [emailInputStatus, setEmailInputStatus] = useState<InputFieldErrors | null>(null)

	const onEmailChange = (value: string) => {
		if (emailErrorMessage || otpError) {
			accountLinkingService.clearEmailError()
		}
		accountLinkingService.setEmail(value)
	}

	const onSubmitEmail = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		await accountLinkingService.sendEmailForLinkingFromProfileEditPage(email)
	}

	useEffect(() => {
		if (emailErrorMessage || otpError || !email) {
			setSubmitDisabled(true)
			setEmailInputStatus(InputFieldErrors.ERROR)
		} else {
			setSubmitDisabled(false)
			setEmailInputStatus(null)
		}
	}, [emailErrorMessage, otpError, email])

	return (
		<form id="account-email-linking" className="account-email-linking-modal" onSubmit={onSubmitEmail}>
			<InputField
				label={t("auth.signup.input.label")}
				type="email"
				placeholder={t("login.email")}
				onChange={onEmailChange}
				status={emailInputStatus}
				message={emailErrorMessage || otpError}
			/>
			<Button
				form="account-email-linking"
				isLoading={otpRequestLoading}
				variant="secondary"
				size="large"
				isUppercased
				type="submit"
				disabled={submitDisabled}
			>
				{t("auth.linkAccount.step1.button")}
			</Button>
		</form>
	)
}
