import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import { useLocation } from "react-router-dom"
import { useToggleSidebar } from "../../hooks/useToggleSidebar"

import cn from "classnames"
import { userStore } from "../../services/user/store"
import { userService } from "../../services/user/service"
import actionPointsService from "../../services/actionPoints/service"

import { Sidebar } from "../../components/Sidebar"
import { TopBarRestyled } from "../../components/TopBar/TopBarRestyled"
import { AppRoutes } from "../../constants/routes"

import "./BaseLayout.scss"

const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
	const { pathname } = useLocation()
	const headerRef = useRef<HTMLDivElement>(null)
	const windowWidth = useWindowWidth()
	const [actualElementWidth, setActualElementWidth] = useState(0)

	const { sidebarRef, isSidebarOpen, toggleSidebar, handleClickOutsideSidebar } = useToggleSidebar()

	const { user } = userStore()

	const isAuthRequired = !(pathname === AppRoutes.staking || pathname === AppRoutes.vesting)

	useEffect(() => {
		setActualElementWidth(headerRef.current?.offsetHeight!)
	}, [windowWidth])

	useEffect(() => {
		if (!user?.id && localStorage.getItem("accessToken")) {
			actionPointsService.getActionPoints().catch(console.warn)
		}
		if (!user && isAuthRequired) {
			userService.getUserData()
		}
	}, [user])

	return (
		<div className="base-layout">
			<div
				ref={sidebarRef}
				className={cn("base-layout__sidebar-wrapper", isSidebarOpen && "base-layout__sidebar-wrapper--show")}
			>
				<Sidebar toggleSidebar={toggleSidebar} />
			</div>
			<div className={cn("base-layout__body", isSidebarOpen && "base-layout__body--hidden")}>
				<div ref={headerRef} className="base-layout__header">
					<TopBarRestyled toggleSidebar={toggleSidebar} />
				</div>
				<div
					className="base-layout__content"
					style={windowWidth < 768 ? {} : { height: `calc(100svh - ${actualElementWidth}px)` }}
				>
					{children}
				</div>
			</div>
			<div
				className={cn("base-layout__overlay", isSidebarOpen && "base-layout__overlay--show")}
				onClick={handleClickOutsideSidebar}
			/>
		</div>
	)
}

export default BaseLayout
