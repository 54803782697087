import {useAccount, useReadContract} from "wagmi";
import {claimContractData} from "../web3/contracts";


export const useWrapperReadClaimContract = (funcName: string, funcArgs?: any[]) => {
  const { address } = useAccount();

  const {
    data,
    isLoading,
    isSuccess,
    refetch,
  } = useReadContract({
    ...claimContractData,
    functionName: funcName,
    args: [address, ...(funcArgs ? [...funcArgs] : [])],
  });

  return {
    data: (data as bigint),
    refetch,
    isLoading,
    isSuccess,
  }
}
