import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useWindowSize from "../../../../hooks/useWindowSize"
import { useCharactersStore } from "../../store"

import { ClaimStatus, NftPropsRaw } from "../../types"
import { ClaimedItem } from "./ClaimedItem"

import css from "./ClaimedList.module.scss"
import { Button } from "../../../../components/Buttons/Button"

const mobileLayoutBreakpoint = 576

export const ClaimedList = () => {
	const { t } = useTranslation()
	const windowSize = useWindowSize()
	const [isSelectMode, setIsSelectMode] = useState(false)
	const [elementsWillFitInTheList, setElementsWillFitInTheList] = useState(1)
	const [resultNftList, setResultNftList] = useState<((NftPropsRaw & { selected?: boolean }) | null)[]>([])
	const { selectedCollection, actions, selectedNftToBuy } = useCharactersStore()
	const listRef = useRef<HTMLUListElement | null>(null)

	const allNfts = useMemo(() => {
		if (selectedCollection?.claimsData) {
			return [
				...(selectedCollection.claimsData.currentProcessingClaims || []),
				...(selectedCollection.claimsData.claims || []),
			]
		}
		return []
	}, [selectedCollection])

	const selectedNfts = useMemo(() => resultNftList.filter(el => !!el?.selected), [resultNftList])
	const isSelectionDisabled = useMemo(
		() => resultNftList.filter(el => el?.claimStatus === ClaimStatus.started),
		[resultNftList],
	)

	const toggleSelectMode = () => {
		if (isSelectMode) {
			setResultNftList(prev =>
				prev.map(el => {
					if (el?.selected) {
						el.selected = false
					}
					return el
				}),
			)
		}
		setIsSelectMode(prev => !prev)
	}

	const handleSelect = (id: string) => {
		setResultNftList(prev => {
			const newList = [...prev]
			const index = newList.findIndex(el => el?.id === id)
			if (index >= 0 && newList[index]) {
				newList[index].selected = !newList[index].selected
				return newList
			}
			return prev
		})
	}

	const onClaimClick = () => {
		actions.setIsNftCongratsModalOpen(true)
	}

	useEffect(() => {
		if (windowSize.width < mobileLayoutBreakpoint) {
			setElementsWillFitInTheList(12)
		} else {
			const listHeight = listRef.current?.offsetHeight
			if (listHeight) {
				setElementsWillFitInTheList(Math.floor(listHeight / 85))
			}
		}
	}, [windowSize])

	useEffect(() => {
		if (allNfts.length > elementsWillFitInTheList) {
			if (windowSize.width < mobileLayoutBreakpoint) {
				const emptyItems = Array.from<null>({ length: 3 - (allNfts.length % 3) }).fill(null)
				setResultNftList([...allNfts, ...emptyItems])
			} else {
				setResultNftList([...allNfts, null, null])
			}
		} else {
			const emptyItems = elementsWillFitInTheList - allNfts.length
			setResultNftList(allNfts.concat(Array.from({ length: emptyItems })))
		}
	}, [elementsWillFitInTheList, allNfts])

	useEffect(() => {
		if (selectedNfts.length) {
			actions.setSelectedNftsToBuy(selectedNfts.filter(el => !!el))
		}
	}, [actions, selectedNfts])

	useEffect(() => {
		if (!isSelectionDisabled.length) {
			setIsSelectMode(false)
		}
	}, [isSelectionDisabled])

	return (
		<div className={css.wrapper}>
			<div className={`${css.wrapper_inner}`}>
				<div className={css.wrapper_inner_header}>
					<h3 className={css.wrapper_inner_title}>{t("claim.characters.claimed.title")}</h3>
					<Button
						onClick={toggleSelectMode}
						variant={isSelectMode ? "primary" : "secondary"}
						size={windowSize.width < mobileLayoutBreakpoint ? "medium" : "regular"}
						disabled={!isSelectionDisabled.length}
					>
						{isSelectMode ? t("claim.characters.button.cancel") : t("claim.characters.button.select")}
					</Button>
				</div>
				<div className={css.wrapper_inner_body}>
					<ul ref={listRef} className={css.wrapper_inner_list}>
						{resultNftList.map((el, i) => (
							<ClaimedItem
								key={el?.id || `${el?.createdAt}` + i}
								isSelectMode={isSelectMode}
								selected={el?.selected || false}
								handleSelect={handleSelect}
								{...el}
							/>
						))}
					</ul>
					<div className={css.wrapper_bottom_fade} />
					{isSelectMode && (
						<Button
							size="large"
							variant="secondary"
							className={css.button}
							disabled={!selectedNfts.length}
							onClick={onClaimClick}
						>
							{t("claim.characters.claim.button.claim")}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}
