import { Button } from "../../../../components/Buttons/Button"

import styles from "./NftCollectionItem.module.scss"
import { NftCollectionShowCase } from "../NftCollectionShowCase"
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution"
import { NftCollection } from "../../types"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../../../constants/routes"
import { createRoutingLink } from "../../../../utils/createRoutingLink"
import { useTranslation } from "react-i18next"

type INftCollectionItemProps = {
	collection?: NftCollection
	nftsCount?: number
}

export const NftCollectionItem: React.FC<INftCollectionItemProps> = ({ collection, nftsCount = 0 }) => {
	const { t } = useTranslation()
	const isMobile = useIsMobileResolution(576)
	const navigate = useNavigate()

	const handleClickGet = () => {
		navigate(createRoutingLink(AppRoutes.characterCollection, { collectionId: collection?.id }))
	}

	if (!collection) {
		return null
	}

	return (
		<div className={styles.borderGradientContainer}>
			<div className={styles.wrapper}>
				<NftCollectionShowCase collection={collection} />
				<div className={styles.footer}>
					<div className={styles.text}>
						<h3 className={styles.title}>{collection.name}</h3>
						<span className={styles.amount}>
							{nftsCount}
							<span className={styles.amountLabel}>NFTs</span>
						</span>
					</div>
					<Button
						onClick={handleClickGet}
						variant="secondary"
						size={isMobile ? "regular" : "large"}
						style={{ width: "initial" }}
						isUppercased
					>
						{t("claim.characters.collection.button.get")}
					</Button>
				</div>
			</div>
		</div>
	)
}
