import {userProfileService} from "../../pages/profile/service/service";
import {BaseService} from "../../services/BaseService";
import {$api, AUTH_API_URL} from "../../services/interceptor";
import {getSocialId} from "../../utils/getSocialId";
import {userStore} from "./store";
import {IEditAvatarPayload, IUserStore, Social, SocialName, SocialsType, UserData, UserPrivacySettings,} from "./types";

export interface TelegramAuthData {
  id: number;
  first_name: string;
  auth_date: number;
  hash: string;
  last_name?: string;
  photo_url?: string;
  username?: string;
}

class UserAPI extends BaseService<IUserStore> {
  constructor() {
    super(userStore);
  }

  async getUserData(successCallback?: () => void) {
    try {
      this.setState({loading: true});
      const response = await $api.get<UserData>("/users/@me");

      this.setState({
        user: response.data,
      });
      successCallback && successCallback();
    } catch (error) {
      // if ((error as AxiosError)?.response?.status === 404) {
      //   localStorage.removeItem("accessToken")
      // }
      this.setState({error: true});
    } finally {
      this.setState({loading: false});
    }
  }

  async editProfile({
                      payload,
                    }: {
    payload: IEditAvatarPayload;
    userId: string;
  }) {
    try {
      this.setState({loading: true});
      await $api.patch<UserData>(`/users/@me`, payload);
      await this.getUserData();

      const userID = this.getState().user?.id || "";

      if (userID) {
        await userProfileService.getUserData(userID);
      }

      return true;
    } catch (e) {
      throw e;
    } finally {
      this.setState({loading: false});
    }
  }

  connectTwitter = async (requestData: string) => {
    try {
      const {data} = await $api.post<SocialsType>(
        `/auth/user-socials/connect/twitter?code=${requestData}`,
        undefined,
        {baseURL: AUTH_API_URL}
      );
      await userService.getUserData();
      // clear query
      window.history.pushState({}, document.title, window.location.pathname);
      return data;
    } catch (error: any) {
      this.setState({socialError: error.response.data.message});
      window.history.pushState({}, document.title, window.location.pathname);
    }
  };

  connectDiscord = async (
    requestData: string,
    successCallback?: () => void
  ) => {
    try {
      const {data} = await $api.post<SocialsType>(
        "/auth/user-socials/connect/discord",
        {
          code: requestData,
          redirectUrl: window.location.origin + "/profile",
        },
        {baseURL: AUTH_API_URL}
      );
      await userService.getUserData();
      window.history.pushState({}, document.title, window.location.pathname);
      successCallback && successCallback();
      return data;
    } catch (error: any) {
      this.setState({socialError: error.response.data.message});
      window.history.pushState({}, document.title, window.location.pathname);
    }
  };

  connectTelegram = async (requestData: TelegramAuthData) => {
    try {
      const {data} = await $api.post<SocialsType>(
        "/auth/user-socials/connect/telegram",
        requestData,
        {baseURL: AUTH_API_URL}
      );
      await userService.getUserData();
      return data;
    } catch (error: any) {
      this.setState({socialError: error.response.data.message});
      window.history.pushState({}, document.title, window.location.pathname);
    }
  };

  getSocialVisibility = (social: SocialName | "Email" | Social) => {
    const user = this.getState().user;
    if (!user || !user.privacySettings) {
      return false;
    }
    const key = `show${social}` as keyof UserPrivacySettings;

    return user.privacySettings[key] || false;
  };

  isAnySocialVisible = () => {
    const user = this.getState().user;

    if (!user || !user.connectedSocials) {
      return false;
    }

    for (const key of user.connectedSocials) {
      if (this.getSocialVisibility(key.social.name)) {
        return true;
      }
    }

    return false;
  };

  disconnectSocial = async (socialName: SocialName) => {
    const socialId = getSocialId(socialName);

    if (!socialId) {
      return;
    }

    try {
      await $api.delete(`/auth/user-socials/disconnect/${socialId}`, {baseURL: AUTH_API_URL});
      await userService.getUserData();

      const userID = this.getState().user?.id || "";

      if (userID) {
        await userProfileService.getUserData(userID);
      }
    } catch (error) {
      // return handleSignUpError(error, setState);
    }
  };

  subtractSpendablePoints = (amountToSubtract: number) => {
    this.setState((currentState) => {
      if (currentState.user && currentState.user.spendablePoints) {
        return {
          ...currentState,
          user: {
            ...currentState.user,
            spendablePoints: {
              ...currentState.user.spendablePoints,
              amount:
                currentState.user.spendablePoints.amount - amountToSubtract,
            },
          },
        };
      }

      return currentState;
    });
  };

  clearStore = () => {
    this.setState({
      user: null,
      loading: false,
      error: false,
      socialError: "",
      reactions: [],
    })
  }
}

export const userService = new UserAPI();
