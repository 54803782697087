import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react"
import { Loader } from "../../Loader"
import styles from "./button.module.scss"
import classNames from "classnames"

type IButtonProps = {
	isUppercased?: boolean
	isLoading?: boolean
	className?: string
	size?: "regular" | "large" | "medium" | "small"
	variant?: "primary" | "secondary" | "green" | "white"
	decoration?: "filled" | "outlined"
	decorationColor?: "gray" | "purple"
	iconButton?: boolean
	decorationBefore?: JSX.Element
	decorationAfter?: JSX.Element
}

export const Button: FC<IButtonProps & PropsWithChildren & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className,
	isLoading = false,
	isUppercased = false,
	disabled,
	variant = "primary",
	size = "regular",
	decoration = "filled",
	decorationColor = "gray",
	decorationBefore,
	decorationAfter,
	iconButton = false,
	...props
}) => {
	return (
		<button
			{...props}
			className={classNames(
				styles.button,
				isUppercased && styles.upper,
				styles[size],
				styles[variant],
				styles[decoration],
				styles["decorColor-" + decorationColor],
				iconButton ? styles.iconButton : "",
				className,
			)}
			disabled={disabled || isLoading}
		>
			{isLoading ? (
				<Loader color={variant !== "primary" ? "#FFFFFF" : undefined} />
			) : (
				<>
					{decorationBefore || null}
					{children}
					{decorationAfter || null}
				</>
			)}
		</button>
	)
}
