import React, { useMemo } from "react"

import { SendTransactionResponse, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react"
import { useTranslation } from "react-i18next"

import { charactersService } from "../../../service"
import { charactersStore } from "../../../store"
import { nftClaim } from "../../../utils"

import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader"
import { Button } from "../../../../../components/Buttons/Button"
import { RarityChip } from "../../ClaimedEarly/RarityChip"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper/modules"

import { RarityType } from "../../../types"

import { NFT_MULTICLAIM_CONTRACT_ADDRESS } from "../../../constants"

import css from "./NftCongratsModal.module.scss"

type INftCongratsModalProps = {
	open: boolean
	onClose: () => void
}

export const NftCongratsModal: React.FC<INftCongratsModalProps> = ({ open, onClose }) => {
	const { t } = useTranslation()
	const { isAbleToNewClaim, selectedCollection, batchClaimGeneratedItems, claimedNftItems, selectedNftToBuy, actions } =
		charactersStore()

	const [tonConnectUI] = useTonConnectUI()
	const wallet = useTonAddress()

	const isAbleToClaim =
		!claimedNftItems?.length && (!!batchClaimGeneratedItems?.claims.length || !!selectedNftToBuy?.length)

	const isClaimDisabled =
		!selectedCollection ||
		(!isAbleToNewClaim && !(selectedCollection.collection.price && selectedCollection.collection.address))

	const handleClick = async () => {
		const itemsToClaim = batchClaimGeneratedItems?.claims.length ? batchClaimGeneratedItems?.claims : selectedNftToBuy
		if (itemsToClaim && NFT_MULTICLAIM_CONTRACT_ADDRESS) {
			let response: SendTransactionResponse | null = null
			if (
				!batchClaimGeneratedItems?.paymentDetails.totalAmount &&
				!batchClaimGeneratedItems?.paymentDetails.signature
			) {
				const paymentDetails = await charactersService.generateSignature(itemsToClaim.map(el => el.id))
				if (paymentDetails) {
					response = await nftClaim(
						paymentDetails.price.toString(),
						NFT_MULTICLAIM_CONTRACT_ADDRESS,
						paymentDetails.signature,
						tonConnectUI,
					)
				}
			} else {
				const {
					paymentDetails: { signature, totalAmount },
				} = batchClaimGeneratedItems
				response = await nftClaim(totalAmount.toString(), NFT_MULTICLAIM_CONTRACT_ADDRESS, signature, tonConnectUI)
			}

			if (response) {
				await charactersService.setClaimStatusToProcessing(
					wallet,
					itemsToClaim.map(el => el.id),
				)
			}
		}
	}

	const onModalClose = () => {
		if (claimedNftItems?.length) {
			actions.closeCongratsModalAfterBatchBuy()
		} else {
			actions.closeCongratsModalBeforeBatchBuy()
		}
	}

	const itemsToDisplay = useMemo(() => {
		const claimableItems = batchClaimGeneratedItems?.claims.length
			? batchClaimGeneratedItems?.claims
			: selectedNftToBuy || []
		return isAbleToClaim ? claimableItems : claimedNftItems || []
	}, [batchClaimGeneratedItems?.claims, claimedNftItems, isAbleToClaim, selectedNftToBuy])

	return (
		<ModalWithHeader
			open={open}
			title={t("claim.characters.modal.result.title")}
			onClose={onModalClose}
			bodyClassName={css.wrapper}
		>
			<>
				<h3 className={css.title}>{selectedCollection?.collection.name}</h3>
				{itemsToDisplay?.length === 2 ? (
					<div className={css.twoInRow}>
						{itemsToDisplay.map(claim => (
							<div key={claim.id} className={css.nft}>
								{/*{claimStatus === "PROCESSING" ? <NorthShine /> : null}*/}
								<img className={claim.claimStatus === "STARTED" ? "" : css.bordered} src={claim.url} alt="nft" />
								{claim && "rarity" in claim ? (
									<RarityChip rarity={claim.rarity as RarityType} size="large" />
								) : (
									<div className={css.empty} />
								)}
							</div>
						))}
					</div>
				) : (
					<Swiper
						slidesPerView={2}
						centeredSlides={true}
						modules={[FreeMode]}
						spaceBetween={24}
						direction={"horizontal"}
						updateOnWindowResize
					>
						{itemsToDisplay.map(claim => {
							const { url, claimStatus, rarity } = claim
							return (
								<SwiperSlide key={claim.id}>
									<div className={css.nft}>
										{/*{claimStatus === "PROCESSING" ? <NorthShine /> : null}*/}
										<img className={claimStatus === "STARTED" ? "" : css.bordered} src={url} alt="nft" />
										{claim && "rarity" in claim ? (
											<RarityChip rarity={rarity as RarityType} size="large" />
										) : (
											<div className={css.empty} />
										)}
									</div>
								</SwiperSlide>
							)
						})}
					</Swiper>
				)}
				{isAbleToClaim ? (
					<Button onClick={handleClick} disabled={isClaimDisabled} size="large" variant="secondary">
						{t("claim.characters.claim.button.claim")}
					</Button>
				) : null}
			</>
		</ModalWithHeader>
	)
}
