import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { useTimer } from "../../../../../hooks/useTimer"
import { petsStore, usePetsStore } from "../../../store/petsStore"
import { petsService } from "../../../service/service"

import { getDateInMs } from "../../../../../utils/getDateInMS"
import { getErrorMessage } from "../../../../../utils/getErrorMessage"

import { oneDayInMs } from "../../../../../constants/time"

import { Button } from "../../../../../components/Buttons/Button"
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader"

import { IPet } from "../../../types"

import css from "../../../styles.module.scss"
import modalStyles from "../modals.module.scss"

export const BuyModal = () => {
	const { pets } = petsStore(store => store)

	const lastBuyDate = useMemo(() => {
		if (pets.length) {
			const lastBoughtPet = pets.reduce((latestPet: IPet | null, currentPet) => {
				if (currentPet.userPet?.createdAt) {
					if (latestPet && latestPet.userPet?.createdAt) {
						return getDateInMs(currentPet.userPet.createdAt) > getDateInMs(latestPet.userPet.createdAt)
							? currentPet
							: latestPet
					} else {
						return currentPet
					}
				} else {
					return latestPet
				}
			}, null)

			return lastBoughtPet?.userPet?.createdAt
		}
		return ""
	}, [pets])

	const { loading, buyPetInfo, selectedPet } = usePetsStore()

	const newTargetDate = lastBuyDate ? new Date(getDateInMs(lastBuyDate) + oneDayInMs) : new Date()
	const [time, isTimerFinished] = useTimer(newTargetDate)
	const { t } = useTranslation()

	const onClose = () => {
		petsService.setBuyPetInfo(null)
	}

	const handleBuy = async () => {
		if (selectedPet) {
			try {
				await petsService.buyPet({ petId: selectedPet.id })
				petsService.setBuyPetInfo(null)
			} catch (e) {
				petsService.setBuyError(getErrorMessage(e))
			}
		}
	}

	if (!buyPetInfo) {
		return null
	}

	return (
		<ModalWithHeader
			open={!!buyPetInfo}
			onClose={onClose}
			title={t("pets.buy.proof.title")}
			modalContentClassName={modalStyles.modalContent}
		>
			<div className={css.proofOfActionModalWrapper}>
				<p className={css.proofOfActionSubtitle}>
					{t(`pets.buy.proof.description`, { name: buyPetInfo.petName, points: buyPetInfo.petPrice })}
				</p>
				<div className={css.btnsWrapper}>
					<Button onClick={onClose} size="large" isUppercased>
						{t("buttons.no")}
					</Button>
					<Button
						variant={"secondary"}
						onClick={isTimerFinished ? handleBuy : undefined}
						disabled={loading || !isTimerFinished}
						isLoading={loading}
						size="large"
						isUppercased
					>
						{isTimerFinished ? t("buttons.yes") : `${time.hour}h ${time.minute}m ${time.seconds}s`}
					</Button>
				</div>
			</div>
		</ModalWithHeader>
	)
}
