import React, { FormEvent, useState } from "react"
import { LoginHeader } from "../../../login/components/LoginHeader/LoginHeader"
import { InputField, InputFieldErrors } from "../../../../newComponents/Input"
import referralService from "../../services/referralService"
import { Trans, useTranslation } from "react-i18next"
import { AppRoutes } from "../../../../constants/routes"
import { useNavigate } from "react-router-dom"
import "./SetupStep.scss"
import { useQueryParam } from "../../../../newHooks/useQueryParam"
import { useReferralStore } from "../../store/referralStore"
import { Btn } from "../../../../newComponents/Btn"
import { IconSuccess } from "../../../../newComponents/Icons/IconSuccess"
import { actionPointsStore } from "../../../../services/actionPoints/store"
import { useShallow } from "zustand/react/shallow"

export const SetupStep = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { paramValue } = useQueryParam("ref")

	const referralPointsReward = actionPointsStore(
		useShallow(({ actionPoints }) => actionPoints?.ENTER_REFERRAL_CODE || 30),
	)

	const { successMessage, errorMessage, isPending } = useReferralStore()

	const [referralCode, setReferralCode] = useState(paramValue || "")

	const onReferralCodeSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (referralCode) {
			referralService.sendReferralCode(referralCode).catch(console.error)
		}
	}

	let refInputStatus: InputFieldErrors | null = null
	let refInputMessage = null

	if (errorMessage) {
		refInputStatus = InputFieldErrors.ERROR
		refInputMessage = errorMessage
	}

	if (successMessage) {
		refInputStatus = InputFieldErrors.SUCCESS
		refInputMessage = successMessage
	}

	const headerText = (
		<Trans i18nKey={"setup.enter"} values={{ points: referralPointsReward }} components={{ b: <b /> }} />
	)

	return (
		<section className="setup-step">
			<LoginHeader title={t("setup.title")} text={headerText} />

			<form id="setup-form" onSubmit={onReferralCodeSubmit} className="setup-step__body">
				<InputField
					label={t("setup.input.title")}
					type="text"
					placeholder={t("setup.code")}
					onChange={setReferralCode}
					value={referralCode}
					status={refInputStatus}
					message={refInputMessage}
					disabled={Boolean(successMessage)}
				/>
			</form>

			<footer className="setup-step__footer">
				<Btn form="setup-form" type="submit" variant="grey" isLoading={isPending} disabled={!referralCode} isFullWidth>
					{successMessage ? <IconSuccess /> : t("setup.confirm")}
				</Btn>

				<Btn onClick={() => navigate(AppRoutes.myProfile)} isFullWidth>
					{successMessage ? t("setup.next2") : t("setup.skip")}
				</Btn>
			</footer>
		</section>
	)
}
