import "./ClaimInfo.scss";
import ClaimHeader from "../ClaimHeader/ClaimHeader";
import { useTranslation } from "react-i18next";
import { MetamaskIcon } from "../../../../assets/iconComponents/MetamaskIcon";
import pixFiIcon from "../../../../assets/images/pixFiIcon.png";
import { WalletIcon } from "../../../../assets/iconComponents/WalletIcon";
import { useMemo } from "react";
import { useWriteClaim } from "../../hooks/useWriteClaim";
import { useAccount, useSwitchChain } from "wagmi";
import { walletService } from "../../../../components/WalletSection/service";
import { useReadClaim } from "../../hooks/useReadClaim";
import { Loader } from "../../../../components/Loader";
import { NETWORK } from "../../../../services/web3/rainbow";


const ClaimInfo = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { switchChain } = useSwitchChain();

  useMemo(() => {
    switchChain && switchChain({ chainId: NETWORK.id });
  }, [switchChain]);

  const {
    totalTokens,
    alreadyClaimed,
    availableToClaim,
    alreadyVested,
    remainingToVest,
  } = useReadClaim();

  const { claim, isPending } = useWriteClaim();

  const onOpenWalletsModalClick = () => walletService.openWalletModal();
  const isWalletConnected = useMemo(() => Boolean(address), [address]);
  const isClaimButtonDisabled = isPending || Number(availableToClaim) <= 0;

  const statusTitle = isWalletConnected
    ? t("claim.wallet-status")
    : t("claim.wallet-status-not-connect");

  return (
    <div className="claim-info">
      <ClaimHeader
        title={t("claim.title")}
        status={statusTitle}
        address={address}
      />

      <div className="claim-info__content">
        {!isWalletConnected && (
          <button
            className="claim-info__button"
            onClick={onOpenWalletsModalClick}
          >
            <MetamaskIcon />
            {t("wallet.title")}
          </button>
        )}

        {isWalletConnected && (
          <div className="claim-info__info">
            <ul className="claim-info__list">
              <li className="claim-info__item claim-info__item--colored">
                <strong className="claim-info__title">{t("claim.total-token")}</strong>

                <p className="claim-info__count">
                  <img className="claim-info__img" src={pixFiIcon} alt="pix-fi-icon" />
                  {totalTokens}
                </p>
              </li>

              <li className="claim-info__item">
                <strong className="claim-info__title">{t("claim.available")}</strong>
                <p className="claim-info__count">
                  <img className="claim-info__img" src={pixFiIcon} alt="pix-fi-icon" />
                  {availableToClaim}
                </p>
              </li>
            </ul>

            <button
              className="claim-info__info-button"
              disabled={isClaimButtonDisabled}
              onClick={isClaimButtonDisabled ? undefined : claim}
            >
              <>
                {isPending ? <Loader color="#fff" /> : <WalletIcon />}
                {t("sidebar.claim")}
              </>
            </button>

            <ul className="claim-info__details-list">
              <li className="claim-info__details-item">
                <b className="claim-info__details-item-label">{t("claim.already-vested")}</b>
                <p className="claim-info__details-item-count">{alreadyVested}</p>
              </li>
              <li className="claim-info__details-item">
                <b className="claim-info__details-item-label">{t("claim.remaining-to-vest")}</b>
                <p className="claim-info__details-item-count">{remainingToVest}</p>
              </li>
              <li className="claim-info__details-item">
                <b className="claim-info__details-item-label">{t("claim.already-claimed")}</b>
                <p className="claim-info__details-item-count">{alreadyClaimed}</p>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClaimInfo;
