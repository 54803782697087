import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useTonAddress } from "@tonconnect/ui-react"
import { useShallow } from "zustand/react/shallow"

import { charactersStore } from "./store"
import { charactersService } from "./service"
import { userStore } from "../../services/user/store"

import { ClaimedEarly } from "./components/ClaimedEarly"
import { NftInfoModal } from "./components/modals/NftInfoModal/NftInfoModal"
import { NftCongratsModal } from "./components/modals/NftCongratsModal/NftCongratsModal"
import { Claimer } from "./components/Claimer"
import { ClaimedList } from "./components/ClaimedList"

import css from "./CharacterClaimPage.module.scss"

export const CharacterClaimPage = () => {
	const { collectionId } = useParams()
	const {
		selectedNftToShow,
		isNftInfoModalOpen,
		isNftCongratsModalOpen,
		actions,
		userCollectionsAndNfts,
		isUserNftsLoading,
	} = charactersStore(useShallow(store => store))
	const wallet = useTonAddress()

	const userId = userStore(state => state.user?.id)

	const handleNftInfoModalClose = () => {
		actions.setIsNftInfoModalOpen(false)
	}
	const handleNftCongratsModalClose = () => {
		actions.setIsNftCongratsModalOpen(false)
	}

	useEffect(() => {
		if (userId && wallet) {
			charactersService.getUserNfts(wallet)
		}
	}, [userId, wallet])

	useEffect(() => {
		if (!isUserNftsLoading && collectionId && userCollectionsAndNfts) {
			actions.setSelectedCollectionById(collectionId)
		}
	}, [actions, collectionId, userCollectionsAndNfts, isUserNftsLoading])

	useEffect(() => {
		charactersService.getActiveCollection()
	}, [])

	return (
		<div className={css.wrapper}>
			<Claimer />
			<ClaimedList />
			<ClaimedEarly />
			{selectedNftToShow ? (
				<NftInfoModal open={isNftInfoModalOpen} onClose={handleNftInfoModalClose} nftInfo={selectedNftToShow} />
			) : null}
			<NftCongratsModal open={isNftCongratsModalOpen} onClose={handleNftCongratsModalClose} />
		</div>
	)
}
