import { useTranslation } from "react-i18next"
import pixFiIcon from "../../../assets/images/pixFiIcon.png"
import styles from "../StakingPage.module.scss"
import cn from "classnames"
import { stakingStore } from "../store"
import { useShallow } from "zustand/react/shallow"
import { transformNumber } from "../../../utils/transformNumber"
import { Button } from "../../../components/Buttons/Button"
import { ArrowRightIcon } from "../../../assets/iconComponents/new_design_icons/ArrowRight"
import { useIsMobileResolution } from "../../../hooks/useIsMobileResolution"

type IStakedBalanceProps = {
	handleShowWithdrawModal: () => void
}

export const StakedBalance: React.FC<IStakedBalanceProps> = ({ handleShowWithdrawModal }) => {
	const { t } = useTranslation()
	const isMobile = useIsMobileResolution()
	const { balance, unstaked, stakerStakeCount, stakingInfo } = stakingStore(
		useShallow(({ stakingInfo, stakedBalance, stakers, stakerStakeCount }) => {
			const unstaked = stakers.reduce((p, c) => {
				p = c.unstaked ? p + +c.amount : p
				return p
			}, 0)
			return {
				balance: +stakedBalance + stakingInfo.totalTicketsAmount,
				unstaked,
				stakingInfo,
				stakerStakeCount,
			}
		}),
	)

	return (
		<div className={cn(styles.stakedWrapper)}>
			<div className={styles.header}>
				<h3>{t("staking.staked.coins")}</h3>
				<div className={styles.unstaked}>
					<h3>{t("staking.staked.unstaked")}</h3>
					<div>
						{unstaked} {t("airdrop.claim.pixfi.currency")}
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.purple}>
					<img src={pixFiIcon} alt="pix-fi-icon" />
					<span>{transformNumber(+balance)}</span>
					<span className={styles.symbol}>pixfi</span>
				</div>
				<Button
					onClick={handleShowWithdrawModal}
					disabled={stakerStakeCount + stakingInfo.totalTicketsAmount <= 0}
					iconButton
					size={isMobile ? "small" : "regular"}
				>
					<ArrowRightIcon />
				</Button>
			</div>
		</div>
	)
}
