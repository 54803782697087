import { zeroAddress } from "viem";
import merkleAbi from "./merkleDistributor/MerkleDistributor.json";
import calculatorAbi from "./tenetCalculator/TenetCalculator.json";
import tokenAbi from "./erc20/Erc20ABI.json";
import stakingAbi from "./tenetStaking/TenetStakingNative.json";
import { MERKLE_ADDRESSES } from "../../raffles";
import withdrawAbi from "../contracts/withdraw/withdraw.json";
import {calculatorAddress, stakingAddress, tokenAddress, withdrawAddress} from "./constants";
import {ContractsData} from "./types";

export const CONTRACTS_DATA: ContractsData = {
  merkleDistributor: {
    abi: merkleAbi,
    address: zeroAddress,
    addresses: MERKLE_ADDRESSES,
  },
  staking: {
    abi: stakingAbi,
    address: stakingAddress,
  },
  calculator: {
    abi: calculatorAbi,
    address: calculatorAddress,
  },
  token: {
    abi: tokenAbi,
    address: tokenAddress,
  },
  withdraw: {
    abi: withdrawAbi,
    address: withdrawAddress,
  },
};
