import {Trans, useTranslation} from "react-i18next"
import {Button} from "../../../../../components/Buttons/Button"
import accountLinkingService from "../../../../login/services/accountLinkingService"
import {useAccountLinkingStore} from "../../../../login/store/accountLinkingStore"
import {AccountLinkingSteps} from "../../../../login/types"
import {ConnectEmailMainModalWrapper} from "../MainModalComponent/ConnectEmailMainModalWrapper"
import "./AccountLinkingModal.scss"
import {AccountLinkingStep1} from "./AccountLinkingStep1"
import {AccountLinkingStep2} from "./AccountLinkingStep2"

type IStepComponentType = Record<AccountLinkingSteps, {
  component: JSX.Element,
  title: string,
  text: JSX.Element | string
}>

export const AccountLinkingModal = () => {
  const {t} = useTranslation()
  const {isAccountLinkingModalOpen, accountLinkingStep, email} = useAccountLinkingStore()

  const step: IStepComponentType = {
    "email": {
      component: <AccountLinkingStep1/>,
      title: t("auth.linkAccount.step1.title"),
      text: t("auth.linkAccount.step1.text"),
    },
    "otp": {
      component: <AccountLinkingStep2/>,
      title: t("auth.linkAccount.step1.title"),
      text: <Trans
        components={{white: <span/>}}
        values={{email: email || ""}}
        i18nKey="auth.linkAccount.step2.text"
      />,
    },
    "congrats": {
      component: <Button
        size="large"
        variant="secondary"
        isUppercased
        onClick={accountLinkingService.closeCongrats}
      >{t("global.close")}</Button>,
      title: t("auth.linkAccount.step.congrats.title"),
      text: t("auth.linkAccount.step.congrats.text"),
    }
  }


  return <ConnectEmailMainModalWrapper
    open={isAccountLinkingModalOpen}
    title={step[accountLinkingStep].title}
    text={step[accountLinkingStep].text}
    onClose={() => accountLinkingService.setIsAccountLinkingModalOpen(false)}
    steps={accountLinkingStep === "congrats" ? undefined : {
      totalSteps: 2,
      currentStep: accountLinkingStep === "email" ? 1 : 2
    }}
  >
    {step[accountLinkingStep].component}
  </ConnectEmailMainModalWrapper>
}
