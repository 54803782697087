import {toBn, toHRNumberFloat} from "../../../utils/bigNumber";

export const formatClaimData = (data: bigint, decimal?: number, isRound?: boolean) => {
  if (!data) return "0";

  let formatted = toBn(toHRNumberFloat(toBn(`${data}`), 18)).toFixed(decimal || 1);

  if (isRound) {
    formatted = Math.floor(Number(formatted)).toString();
  }

  return formatted;
}
