import React, { ChangeEvent } from "react"

import css from "./InputNumber.module.scss"
import IconMinus from "../Icons/IconMinus"
import IconPlus from "../Icons/IconPlus"

type IInputNumberProps = {
	value: number
	onChange: (value: number) => void
	plusHandler?: (value: number) => void
	minusHandler?: (value: number) => void
}

const InputNumber: React.FC<IInputNumberProps> = ({ value, onChange, plusHandler, minusHandler }) => {
	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(parseInt(event.currentTarget.value))
	}

	const onMinusClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		minusHandler && minusHandler(parseInt(e.currentTarget.value) || 0)
	}

	const onPlusClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		plusHandler && plusHandler(parseInt(e.currentTarget.value) || 0)
	}

	return (
		<div className={css.wrapper}>
			{minusHandler && (
				<button onClick={onMinusClick}>
					<IconMinus />
				</button>
			)}
			<input value={value} onChange={e => changeHandler(e)} />
			{plusHandler && (
				<button onClick={onPlusClick}>
					<IconPlus />
				</button>
			)}
		</div>
	)
}

export default InputNumber
