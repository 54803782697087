import {useAccount, useWriteContract} from "wagmi";
import {claimContractData} from "../web3/contracts";
import {useQueryClient} from "@tanstack/react-query";

export const useWriteClaim = () => {
  const { address } = useAccount();

  const {
    isSuccess,
    isPending,
    writeContract,
  } = useWriteContract();

  const claim = () => {
    writeContract({
      ...claimContractData,
      functionName: "release",
      args: [address],
    })
  }

  return {
    claim,
    isSuccess,
    isPending,
  }
}
