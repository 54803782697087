import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { userProfileService } from "./service/service"
import { userStore } from "../../services/user/store"
import { userService } from "../../services/user/service"
import { navigateToUserProfile } from "./helpers"

import { UserProfile } from "./UserProfile"

type IProfileProps = {
	fromMy?: boolean
}

export const Profile: React.FC<IProfileProps> = () => {
	const { userID: userIdFromUrlParams } = useParams()
	const userFromState = userStore(useShallow(({ user }) => user))

	useEffect(() => {
		if (userFromState?.id && !userIdFromUrlParams) {
			navigateToUserProfile()
		} else if (userFromState?.id) {
			if (userIdFromUrlParams && userIdFromUrlParams === userFromState.id) {
				userProfileService.setState({ user: userFromState })
				userProfileService.getUserReactions(userFromState.id)
			} else if (userIdFromUrlParams && userIdFromUrlParams !== userFromState.id) {
				userProfileService.getUserData(userIdFromUrlParams).then(() => {
					userProfileService.getUserReactions(userIdFromUrlParams)
				})
			} else {
				userProfileService.setState({ user: userFromState })
				userProfileService.getUserReactions(userFromState.id)
			}
		} else {
			// userService.getUserData()
		}
	}, [userIdFromUrlParams, userFromState?.id])
	return userIdFromUrlParams ? <UserProfile /> : null
}
