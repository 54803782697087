import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import css from "./styles.module.scss";
import Modal from "@mui/material/Modal";
import { ModalProps } from "@mui/material/Modal/Modal";
import { Zoom } from "@mui/material";
import { PurpleStackingGradient } from "../../../../assets/iconComponents/PurpleStackingGradient";
import PixelTkImg from "../../../../assets/images/pixel-token.png";
import { CloseIconPlain } from "../../../../assets/iconComponents/CloseIconPlain";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, FreeMode } from "swiper/modules";
import { SliderArrow } from "../../../../assets/iconComponents/SliderArrow";
import { StackingCard } from "./components/StackingCard";
import { HistoryCard } from "./components/HistoryCard";
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution";
import { StackingBackIcon } from "../../../../assets/iconComponents/StackingBackIcon";
import { StakeSubModal } from "./components/StakeSubModal";
import { stakingStore } from "../../store";
import { stakingService } from "../../service";
import { useAccount, useWriteContract } from "wagmi";
import classNames from "classnames";
import { GenesisWithdrawSection } from "./components/GenesisWithdrawSection";
import { useTranslation } from "react-i18next";
import { CONTRACTS_DATA } from "../../web3/contracts";
import { RaffleState } from "../../types";

interface PropsTypes {
  title: string;
  type: "history" | "withdraw";
  children: React.ReactNode;
  titleValue: string | number;
}
const GENESIS_PERCENT = 10;

export const StackingModal = ({ open, onClose, title, children, titleValue, type, ...rest }: PropsTypes & ModalProps) => {
  const isWithdrawType = type === "withdraw";
  const swiperRef = useRef<SwiperRef | null>(null);
  const isMobile = useIsMobileResolution();
  const { t } = useTranslation();

  const [subModalOpen, setSubModalOpen] = useState<boolean>(false);
  const [subStackingModalOpen, setStackingSubModalOpen] = useState<boolean>(false);
  const [ableToClaim, setAbleToClaim] = useState<boolean>(true);
  const data = stakingStore((state) => state.stakers);
  const { newEpoch, history } = stakingStore();
  const genesis = stakingStore((state) => state.genesis);
   const { address } = useAccount();
  const [isGenesiwWithdrawModal, setGenesiwWithdrawModal] = useState<boolean>(false);
  const [withdrawCardsAmount, setWithdrawCardsAmount] = useState(0);

  const totalWins = useMemo(() => {
    return history.filter(item => {
      const wallet = Array.isArray(item.wallet) ? item.wallet[0] : item.wallet
      return wallet && (wallet.toLowerCase() === address?.toLowerCase())

    }).length

  }, [address, history])

  const { isPending, writeContractAsync: writeContract } = useWriteContract({
    mutation: {
      onSettled: () => {
        //@ts-ignore
        onClose();
      },
    },
  });

  useEffect(() => {
    if (isWithdrawType) {
      if (!data.length) {
        setStackingSubModalOpen(false);
      }
    } else {
      address && stakingService.getUserInformationAndProofsHistory(address)
    }

  }, []);

  // useEffect(() => {
  //   if (isSuccess || isError) {
  //     //@ts-ignore
  //     onClose();

  //     console.log("closed");
  //   }
  // }, [isSuccess, isError, onClose]);

  const handleWithdraw = async () => {
    //TODO handle if timer started
    if (address) {
      try {
        const signData = await stakingService.getWithdrawSignature(address);
        const amount = BigInt(signData.amount);
        const used = signData.used;
        const receiver = signData.receiver;
        const signature = signData.signature;

        if (used) return;
        if (!amount || !receiver || !signature) return;

        await writeContract({
          address: CONTRACTS_DATA.withdraw.address,
          abi: CONTRACTS_DATA.withdraw.abi,
          functionName: "claim",
          args: [receiver, amount, signature],
        });
      } catch (e) {
        console.log("Failed to withdraw: ", e);
      }
    }
  };

  const onToggleGenesisModal = () => {
    setGenesiwWithdrawModal((state) => !state);
  };

  useLayoutEffect(() => {
    const length = genesis ? data.length + 1 : data.length;

    setWithdrawCardsAmount(length);
  }, [data, genesis]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{
        root: css.modalRoot,
        backdrop: css.modalBackdropWithdraw,
      }}
      sx={{ outline: "none !important" }}
      {...rest}
    >
      <Zoom in={open} style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}>
        <section className={classNames(css.modalContent, { [css.modalContentGenesisWithdraw]: isGenesiwWithdrawModal })}>
          <div className={css.titleWrapper}>
            {<PurpleStackingGradient className={css.gradient} />}
            <div className={css.left}>
              <StackingBackIcon
                className={css.backIcon}
                onClick={() => {
                  // @ts-ignore
                  onClose();
                }}
              />
              <hr className={css.divider} />
              <h2 className={css.titleText}>{title}</h2>
            </div>
            <div className={css.right}>
              {isWithdrawType ? <div className={css.totalWrapper}>
                <span className={css.stackedLabel}>{"total staked:"}</span>
                <div className={css.stackedValue}>
                  <img src={PixelTkImg} alt="token icon" className={css.tkImg} />
                  {titleValue} PIXFI
                </div>
              </div> : <div className={css.totalWrapper}>
              </div>}
              <CloseIconPlain
                className={css.closeIcon}
                onClick={() => {
                  // @ts-ignore
                  onClose();
                }}
              />
            </div>
          </div>

          {isGenesiwWithdrawModal && genesis && isWithdrawType ? (
            <GenesisWithdrawSection
              genesis={genesis}
              GENESIS_PERCENT={GENESIS_PERCENT}
              onToggleGenesisModal={onToggleGenesisModal}
              handleWithdraw={handleWithdraw}
              isPending={isPending}
            />
          ) : (
            <div className={css.cardsWrapper}>
              {isMobile ? (
                <>
                  {genesis && isWithdrawType && (
                    <StackingCard
                      setSubModalOpen={setStackingSubModalOpen}
                      {...genesis}
                      onToggleGenesisModal={onToggleGenesisModal}
                    />
                  )}

                  {isWithdrawType
                    ? data.map((item, i) => (
                      <StackingCard key={item.id + i} setSubModalOpen={setStackingSubModalOpen} {...item} />
                    ))
                    : history.map((item, i) => {
                      return <HistoryCard
                        key={item.id + i}
                        setSubModalOpen={setSubModalOpen}
                        epochIndex={+item.epochIndex}
                        snapshotDate={(+item.epoch.epochEndTimestamp * 1000).toString()}
                        lotteryHeldDate={new Date(+item.epoch.epochEndTimestamp * 1000)}
                        contractAddress=""
                        isEthChainConnected
                        isWinner={item.status === RaffleState.won}
                        ticketsAmount={item.amount}
                      />
                    })
                  }
                  <div style={{ height: 50 }} />
                </>
              ) : (
                <Swiper
                  key={withdrawCardsAmount}
                  ref={swiperRef}
                  modules={[Navigation, FreeMode]}
                  freeMode={true}
                  spaceBetween={33}
                  direction={"horizontal"}
                  breakpoints={{
                    1050: { slidesPerView: 2 },
                    1260: {
                      slidesPerView: withdrawCardsAmount === 1 ? 1 : withdrawCardsAmount === 2 ? 2 : 2.5,
                    },
                  }}
                >
                  {genesis && isWithdrawType && (
                    <SwiperSlide key={"staticItem"} style={{ display: "flex", justifyContent: "center" }}>
                      <StackingCard
                        setSubModalOpen={setStackingSubModalOpen}
                        {...genesis}
                        onToggleGenesisModal={onToggleGenesisModal}
                      />
                    </SwiperSlide>
                  )}

                  {isWithdrawType
                    ? data.map((item, i) => (
                      <SwiperSlide key={item.id + i} style={{ display: "flex", justifyContent: "center" }}>
                        <StackingCard setSubModalOpen={setStackingSubModalOpen} {...item} />
                      </SwiperSlide>
                    ))
                    : history.map((historyItem, i) => <SwiperSlide key={historyItem.id + i} style={{ display: "flex", justifyContent: "center" }}>
                      <HistoryCard
                        setSubModalOpen={setSubModalOpen}
                        epochIndex={+historyItem.epochIndex}
                        snapshotDate={(+historyItem.epoch.epochEndTimestamp * 1000).toString()}
                        lotteryHeldDate={new Date(+historyItem.epoch.epochEndTimestamp * 1000)}
                        contractAddress=""
                        isEthChainConnected
                        isWinner={historyItem.status === RaffleState.won}
                        ticketsAmount={historyItem.amount}
                      />
                    </SwiperSlide>)
                  }
                  {data.length > 2 && (
                    <SwiperSlide key={"staticItemEnd"} style={{ width: 32 }}>
                      <div style={{ width: 32 }} />
                    </SwiperSlide>
                  )}
                </Swiper>
              )}
              <div className={css.btnsWrapper}>
                <div className={css.bottomDetails}>
                  {!isWithdrawType && (
                    <div className={css.totalWins}>
                      {t("staking.history.totalWins")} <b>{totalWins}</b>
                    </div>
                  )}
                </div>
                <div className={css.btnsContentWrapper}>
                  <button className={css.prevBtn} onClick={() => swiperRef?.current?.swiper.slidePrev()}>
                    <SliderArrow className={css.arrow} />
                    {t("staking.withdrawModal.left")}
                  </button>
                  <button className={css.nextBtn} onClick={() => swiperRef?.current?.swiper.slideNext()}>
                    <SliderArrow className={css.arrow} />
                    {t("staking.withdrawModal.right")}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div>{children}</div>

          <StakeSubModal
            open={subModalOpen}
            onClose={() => setSubModalOpen(false)}
            isWinner={true}
            subtitle={ableToClaim ? t("staking.stakingModal.Shards") : undefined}
            paragraph={ableToClaim ? t("staking.lockYourFunds") : undefined}
            btnText={ableToClaim ? "Take it" : undefined}
            onProceed={() => (ableToClaim ? setSubModalOpen(false) : setAbleToClaim(true))}
          >
            <></>
          </StakeSubModal>
          <StakeSubModal
            open={subStackingModalOpen}
            onClose={() => setStackingSubModalOpen(false)}
            onProceed={() => {
              stakingService.setState({ isCancelUnstakeSuccess: false });
              setStackingSubModalOpen(false);
            }}
          >
            <></>
          </StakeSubModal>
        </section>
      </Zoom>
    </Modal>
  );
};
