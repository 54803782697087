export const isMainnet = process.env.REACT_APP_IS_MAINNET;

//TESTNET
const tokenTestnet = "0x5fE5A143E62854CBdF878aE68d31B0F1A68975E6";
const stakingTestnet = "0xC7d119143577d4cec2f6517623A78fB22d08F7c3";
const calculatorTestnet = "0x152B386537b9539E66bEC3d2FfC8AecB38a8cF1B";
const withdrawTestnet = "0x29fD7484D0ADEF5124B1200D54265980C6E883DE";

//MAINNET
const token = "0xd795eb12034C2b77d787a22292c26fab5f5C70Aa";
const staking = "0x1729F8Cc02237bD360c94c15a8ee41ab724f19fB";
const calculator = "0x73f003320a096960c1fe08dbBC1566e321125141";
const withdraw = "0xAC049C76F98314d051d7B2692988a39BdE2558c2";

export const tokenAddress = isMainnet ? token : tokenTestnet;
export const stakingAddress = isMainnet ? staking : stakingTestnet;
export const calculatorAddress = isMainnet ? calculator : calculatorTestnet;
export const withdrawAddress = isMainnet ? withdraw : withdrawTestnet;
