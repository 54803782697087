import { SVGAttributes } from "react";

export const MetamaskIcon = ({ ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5836_62013)">
        <path d="M23.1263 0.0224609L13.916 6.88514L15.6196 2.83612L23.1263 0.0224609Z" fill="#D1D1D1" stroke="#D1D1D1"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.86426 0.0224609L11.0005 6.95008L9.3803 2.83612L1.86426 0.0224609Z" fill="#D1D1D1" stroke="#D1D1D1"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.8123 15.9326L17.3594 19.7032L22.6079 21.1516L24.1168 16.0163L19.8123 15.9326Z" fill="#D1D1D1"
              stroke="#D1D1D1" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.89209 16.0163L2.39167 21.1516L7.64019 19.7032L5.18731 15.9326L0.89209 16.0163Z" fill="#D1D1D1"
              stroke="#D1D1D1" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.34413 9.56095L5.88135 11.7808L11.0926 12.013L10.9077 6.39453L7.34413 9.56095Z" fill="#D1D1D1"
              stroke="#D1D1D1" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.6463 9.56046L14.0366 6.3291L13.916 12.0125L19.1184 11.7803L17.6463 9.56046Z" fill="#D1D1D1"
              stroke="#D1D1D1" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.64062 19.7027L10.7692 18.1702L8.06628 16.0527L7.64062 19.7027Z" fill="#D1D1D1" stroke="#D1D1D1"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.2212 18.1702L17.3591 19.7027L16.9242 16.0527L14.2212 18.1702Z" fill="#D1D1D1" stroke="#D1D1D1"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.3591 19.7015L14.2212 18.1689L14.4713 20.2215L14.4433 21.0849L17.3591 19.7015Z" fill="#AAAAAA"
              stroke="#AAAAAA" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.64062 19.7015L10.5564 21.0849L10.5378 20.2215L10.7692 18.1689L7.64062 19.7015Z" fill="#AAAAAA"
              stroke="#AAAAAA" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.6025 14.6972L7.99219 13.9263L9.83406 13.0811L10.6025 14.6972Z" fill="#233447" stroke="#233447"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.3882 14.6972L15.1566 13.0811L17.0078 13.9263L14.3882 14.6972Z" fill="#233447" stroke="#233447"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.64038 19.7032L8.08466 15.9326L5.1875 16.0163L7.64038 19.7032Z" fill="#ECECEC" stroke="#ECECEC"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.915 15.9326L17.3593 19.7032L19.8122 16.0163L16.915 15.9326Z" fill="#ECECEC" stroke="#ECECEC"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.1184 11.7803L13.916 12.0125L14.3976 14.6963L15.166 13.0801L17.0172 13.9253L19.1184 11.7803Z"
              fill="#ECECEC" stroke="#ECECEC" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.99193 13.9253L9.84312 13.0801L10.6022 14.6963L11.0926 12.0125L5.88135 11.7803L7.99193 13.9253Z"
              fill="#ECECEC" stroke="#ECECEC" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.88135 11.7803L8.06598 16.0517L7.99193 13.9253L5.88135 11.7803Z" fill="#D6D6D6" stroke="#D6D6D6"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.017 13.9253L16.9243 16.0517L19.1183 11.7803L17.017 13.9253Z" fill="#D6D6D6" stroke="#D6D6D6"
              strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.0929 12.0137L10.6025 14.6974L11.2135 17.8639L11.3523 13.6943L11.0929 12.0137Z" fill="#D6D6D6"
              stroke="#D6D6D6" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.9156 12.0137L13.666 13.685L13.7769 17.8639L14.3972 14.6974L13.9156 12.0137Z" fill="#D6D6D6"
              stroke="#D6D6D6" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.3972 14.6948L13.7769 17.8612L14.2211 18.1677L16.9241 16.0502L17.0168 13.9238L14.3972 14.6948Z"
              fill="white" stroke="white" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.99219 13.9238L8.06624 16.0502L10.7692 18.1677L11.2135 17.8612L10.6025 14.6948L7.99219 13.9238Z"
              fill="white" stroke="white" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M14.4437 21.0837L14.4716 20.2202L14.2402 20.0156H10.7506L10.5378 20.2202L10.5564 21.0837L7.64062 19.7002L8.65866 20.5356L10.7227 21.9751H14.2681L16.3414 20.5356L17.3595 19.7002L14.4437 21.0837Z"
          fill="#D2D2D2" stroke="#D2D2D2" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M14.2213 18.1698L13.7771 17.8633H11.2133L10.7691 18.1698L10.5376 20.2223L10.7504 20.0177H14.24L14.4714 20.2223L14.2213 18.1698Z"
          fill="#161616" stroke="#161616" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M23.5148 7.33087L24.3014 3.54211L23.126 0.0224609L14.2212 6.65292L17.646 9.55956L22.487 10.9804L23.5609 9.72683L23.098 9.39276L23.8385 8.71481L23.2648 8.26907L24.0052 7.70232L23.5148 7.33087Z"
          fill="#8E8E8E" stroke="#8E8E8E" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M0.698242 3.54211L1.48482 7.33087L0.985118 7.70232L1.72559 8.26907L1.16116 8.71481L1.90163 9.39276L1.43872 9.72683L2.50332 10.9804L7.34435 9.55956L10.7692 6.65292L1.86437 0.0224609L0.698242 3.54211Z"
          fill="#8E8E8E" stroke="#8E8E8E" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.4872 10.9814L17.6462 9.56055L19.1183 11.7804L16.9243 16.0518L19.8122 16.0149H24.1167L22.4872 10.9814Z"
              fill="white" stroke="white" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.344 9.56055L2.50297 10.9814L0.89209 16.0149H5.18731L8.06584 16.0518L5.88121 11.7804L7.344 9.56055Z"
              fill="white" stroke="white" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M13.9159 12.0134L14.2214 6.65469L15.6288 2.83789H9.38037L10.7691 6.65469L11.0928 12.0134L11.2041 13.7033L11.2134 17.8635H13.7771L13.7957 13.7033L13.9159 12.0134Z"
          fill="white" stroke="white" strokeWidth="0.0547843" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_5836_62013">
          <rect width="23.65" height="22" fill="white" transform="translate(0.674805)"/>
        </clipPath>
      </defs>
    </svg>
  );
};
