import React from "react"

import { useTranslation } from "react-i18next"
import { useCharactersStore } from "../../../store"

import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader"
import { CollectionNames } from "../../../types"
import { Button } from "../../../../../components/Buttons/Button"
import InputNumber from "../../../../../newComponents/InputNumber/InputNumber"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Autoplay } from "swiper/modules"

import { NFT_SLIDER_IMAGES } from "../../../constants"

import css from "./NftBuyingModal.module.scss"

type INftBuyingModalProps = {
	open: boolean
	onClose: () => void
	handleSpin: () => void
}

export const NftBuyingModal: React.FC<INftBuyingModalProps> = ({ open, onClose, handleSpin }) => {
	const { t } = useTranslation()
	const {
		selectedCollection,
		batchClaimCount,
		actions: { batchClaimCountPlus, batchClaimCountMinus, setBatchClaimCount },
	} = useCharactersStore()

	const handleClickGenerate = async () => {
		handleSpin()
	}

	return (
		<ModalWithHeader
			open={open}
			title={t("claim.characters.claim.modal.selectAmount.title")}
			onClose={onClose}
			bodyClassName={css.wrapper}
		>
			<>
				<div className={css.slider}>
					<Swiper
						slidesPerView={2}
						allowTouchMove={false}
						loop={true}
						speed={1000}
						autoplay={{
							delay: 0,
						}}
						draggable={false}
						centeredSlides={true}
						modules={[FreeMode, Autoplay]}
						spaceBetween={24}
						direction={"horizontal"}
						className={css.swiper}
					>
						{NFT_SLIDER_IMAGES[selectedCollection?.collection.key || CollectionNames.Puma].map(el => (
							<SwiperSlide key={el.id} className={css.slide}>
								{el.element}
							</SwiperSlide>
						))}
					</Swiper>
				</div>
				<div className={css.footer}>
					<InputNumber
						value={batchClaimCount}
						onChange={setBatchClaimCount}
						minusHandler={batchClaimCountMinus}
						plusHandler={batchClaimCountPlus}
					/>
					<Button onClick={handleClickGenerate} size="large" variant="secondary">
						{t("claim.characters.claim.button.claim")}
					</Button>
				</div>
			</>
		</ModalWithHeader>
	)
}
