import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react"
import { useTranslation } from "react-i18next"
import { WalletIcon } from "../../../../assets/iconComponents/WalletIcon"
import { Button } from "../../../../components/Buttons/Button"
import { shortenAddress } from "../../../../utils"

export const TonWalletDisplay = () => {
	const { t } = useTranslation()
	const friendlyTonAddress = useTonAddress()
	const [tonConnectUI] = useTonConnectUI()

	const handleClick = () => {
		if (!friendlyTonAddress) {
			tonConnectUI.openModal()
		}
	}

	return (
		<Button
			onClick={handleClick}
			style={{
				margin: "0 auto",
				cursor: friendlyTonAddress ? "default" : "pointer",
			}}
			decorationBefore={<WalletIcon />}
		>
			{friendlyTonAddress ? shortenAddress(friendlyTonAddress, 3) : t("wallet.title")}
		</Button>
	)
}
