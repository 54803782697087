import { BaseService } from "../../services/BaseService"
import { $api } from "../../services/interceptor"
import { charactersStore, INftStore } from "./store"
import { getErrorMessage } from "../../utils/getErrorMessage"
import { userStore } from "../../services/user/store"

import {
	EarlyNftType,
	GenerateNftResponse,
	GenerateSignatureResponse,
	NftCollectionActiveResponse,
	NftPropsRaw,
	SetClaimToProcessingResponse,
	UserNftsResponse,
} from "./types"

class NftApi extends BaseService<INftStore> {
	constructor() {
		super(charactersStore)
	}

	getActiveCollection = async () => {
		this.setState({ isUserNftsLoading: true })
		const { userCollectionsAndNfts } = this.getState()
		try {
			if (!userCollectionsAndNfts) {
				const { data } = await $api.get<NftCollectionActiveResponse>("/nft-collections/active")
				this.setState({ userCollectionsAndNfts: data.map(el => ({ collection: el })) })
			}
		} catch (error) {
			console.log(getErrorMessage(error))
		} finally {
			this.setState({ isUserNftsLoading: false })
		}
	}

	getUserNfts = async (wallet: string, shouldUpdate?: boolean) => {
		this.setState({ isUserNftsLoading: true })
		const { user } = userStore.getState()
		const walletParamString = wallet ? "wallet=" + wallet : ""
		const userIdParamString = user?.id ? "userId=" + user.id : ""
		const requestString = `?${walletParamString}&${userIdParamString}`

		const { userCollectionsAndNfts } = this.getState()

		try {
			if (!(userCollectionsAndNfts || [])[0]?.claimsData || shouldUpdate) {
				await this.getEarlyNft(wallet)

				const { data } = await $api.get<UserNftsResponse>("/nft-collection-claims" + requestString)

				this.setState({ userCollectionsAndNfts: data, isUserNftsLoading: false })
			}
		} catch (error) {
			console.log("UserNfts error", getErrorMessage(error))
		} finally {
			this.setState({
				isUserNftsLoading: false,
			})
		}
	}

	generateNftBatch = async (wallet: string) => {
		const { selectedCollection, batchClaimCount } = this.getState()
		const { user } = userStore.getState()

		const request = {
			userId: user?.id,
			wallet,
			nftCollectionId: selectedCollection?.collection?.id,
			quantity: batchClaimCount,
		}

		try {
			const { data } = await $api.post<GenerateNftResponse>(`/nft-collection-claims`, request)

			if (data) {
				this.setState({
					batchClaimGeneratedItems: data,
					selectedCollection: selectedCollection
						? {
								...selectedCollection,
								claimsData: selectedCollection.claimsData
									? {
											...selectedCollection.claimsData,
											claims: selectedCollection.claimsData.claims ? [...selectedCollection.claimsData?.claims] : [],
											currentProcessingClaims: [
												...(selectedCollection.claimsData?.currentProcessingClaims || []),
												...(data.claims || []),
											],
										}
									: undefined,
							}
						: null,
				})
			} else {
				console.log("Warning: NftBatch error")
			}
		} catch (error) {
			console.log("Error getCurrentNftCollection", error)
			user?.id && (await this.getUserNfts(wallet, true))
		}
	}

	generateSignature = async (ids: string[]) => {
		const request = ids.map(el => `claimIds[]=${el}`).join("&")
		try {
			const {
				data: { signature, totalAmount },
			} = await $api.get<GenerateSignatureResponse>(`/nft-collection-claims/signature/generate?${request}`)
			return {
				signature,
				price: totalAmount.toString(),
			}
		} catch (e) {
			console.log(getErrorMessage(e))
			return null
		}
	}

	getEarlyNft = async (wallet: string) => {
		try {
			const { earlyNft } = this.getState()

			if (!earlyNft) {
				const { data } = await $api.get<EarlyNftType[]>(`/nft-collection-claims/early-claimed?wallet=${wallet}`)

				const dashboard = data.find(el => el.source === "dashboard") || null
				const clicker = data.find(el => el.source === "clicker") || null

				this.setState({ earlyNft: { dashboard, clicker } })
			}
		} catch (error) {
			console.log(error)
		}
	}

	setClaimStatusToProcessing = async (wallet: string, nftCollectionClaimIds: string[]) => {
		const request = {
			claimIds: nftCollectionClaimIds,
		}
		try {
			const { data } = await $api.patch<SetClaimToProcessingResponse>(`/nft-collection-claims/processing`, request)
			this.setState({
				claimedNftItems: [...data.claims],
				selectedNftToShow: null,
				isAbleToContinueClaim: false,
				isAbleToNewClaim: false,
				isNftCongratsModalOpen: true,
				isNftInfoModalOpen: false,
			})
			this.getUserNfts(wallet, true)
		} catch (error) {
			console.log(error)
		}
	}
}

export const charactersService = new NftApi()
