import {useWrapperReadClaimContract} from "./useWrapperReadClaimContract";
import {formatClaimData} from "../helpers/formatClaimData";

export const useReadClaim = () => {
  const {data: ra} = useWrapperReadClaimContract("releasableAmount");
  const {data: rtb} = useWrapperReadClaimContract("releasedToBeneficiary");
  const {data: rfb} = useWrapperReadClaimContract("reservedForBeneficiary");

  return {
    totalTokens: formatClaimData(rfb, 1, true),
    alreadyClaimed: formatClaimData(rtb),
    availableToClaim: formatClaimData(ra),
    alreadyVested: formatClaimData(rtb + ra),
    remainingToVest: formatClaimData(rfb - (rtb + ra)),
  }
}
