import { NETWORK_AVALANCHE } from "../../services/web3/rainbow";
import styles from "./StakingPage.module.scss";
import { useEffect, useState } from "react";
import { useAccount, useChainId, usePublicClient, useSwitchChain } from "wagmi";
import { stakingService } from "./service";
import { walletService } from "../../components/WalletSection/service";
import { StakedBalance } from "./components/StakedBalance";
import { PrizePool } from "./components/PrizePool";
import { StakingTickets } from "./components/StakingTickets";
import { Staking } from "./components/Staking";
import { RaffleResult } from "./components/RaffleResult";
import { useStakingStore } from "./store";
import { StakingInfo } from "./components/StakingInfo";
import classNames from "classnames";
import { Rewards } from "./components/Rewards";
import { stakingStore } from "./store";
import { useShallow } from "zustand/react/shallow";
import { StackingWithdrawModal } from "./components/StackingModals/StackingWithdrawModal";
import { StackingHistoryModal } from "./components/StackingModals/StackingHistoryModal";

export const StakingPage = () => {
  const { address } = useAccount();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  const staked = stakingStore(useShallow(({ stakedBalance }) => !!stakedBalance));
  const { isStakingInfoOpen } = useStakingStore();
  const client = usePublicClient();

  const [isModalWithdrawOpen, setIsModalWithdrawOpen] = useState(false);
  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);

  const handleShowHistoryModal = () => {
    setIsModalHistoryOpen(true);
  };
  const handleShowWithdrawModal = () => {
    setIsModalWithdrawOpen(true);
  };

  useEffect(() => {
    stakingStore.setState({ publicClient: client });
  }, [client]);

  useEffect(() => {
    switchChain && switchChain({ chainId: NETWORK_AVALANCHE.id });
  }, [chainId, switchChain])

  useEffect(() => {
    if (address) {
      stakingService.getStakingData(address).then((epoch) => {
        stakingService.getUserInformationAndProofs(epoch !== null ? +epoch : 0, address);

        stakingService.getWithdrawalsInfo(address);
      });
    }
  }, [address]);

  useEffect(() => {
    if (!address) {
      walletService.openWalletModal();
    }
  }, [address]);



  return (
    <>
      {isStakingInfoOpen ? (
        <StakingInfo />
      ) : (
        <div
          className={classNames(
            styles.pageGrid,
            !(staked && address) && styles.notStakedGrid,
          )}
        >
          <Staking />
          <RaffleResult
            handleShowHistoryModal={handleShowHistoryModal}
          />
          {(staked && address) ? <>
            <StakingTickets />
            <PrizePool />
            <StakedBalance
              handleShowWithdrawModal={handleShowWithdrawModal}
            />
          </> : <>
            {/*<StakingHelp />*/}
            <Rewards />
          </>
          }
        </div>
      )}
      <StackingWithdrawModal isModalOpen={isModalWithdrawOpen} setIsModalOpen={setIsModalWithdrawOpen} />
      {isModalHistoryOpen && (
        <StackingHistoryModal isModalOpen={isModalHistoryOpen} setIsModalOpen={setIsModalHistoryOpen} />
      )}
    </>
  );
};
