import Puma0 from "../../assets/images/nft/Puma/0.png"
import Puma1 from "../../assets/images/nft/Puma/1.png"
import Puma2 from "../../assets/images/nft/Puma/2.png"
import Puma3 from "../../assets/images/nft/Puma/3.png"
import Puma4 from "../../assets/images/nft/Puma/4.png"
import Puma5 from "../../assets/images/nft/Puma/5.png"
import Puma6 from "../../assets/images/nft/Puma/6.png"
import Puma7 from "../../assets/images/nft/Puma/7.png"
import Puma8 from "../../assets/images/nft/Puma/8.png"
import Puma9 from "../../assets/images/nft/Puma/9.png"

import Cactus0 from "../../assets/images/nft/Cactus/0.png"
import Cactus1 from "../../assets/images/nft/Cactus/1.png"
import Cactus2 from "../../assets/images/nft/Cactus/2.png"
import Cactus3 from "../../assets/images/nft/Cactus/3.png"
import Cactus4 from "../../assets/images/nft/Cactus/4.png"
import Cactus5 from "../../assets/images/nft/Cactus/5.png"
import Cactus6 from "../../assets/images/nft/Cactus/6.png"
import Cactus7 from "../../assets/images/nft/Cactus/7.png"
import Cactus8 from "../../assets/images/nft/Cactus/8.png"
import Cactus9 from "../../assets/images/nft/Cactus/9.png"
import { CollectionNames, NFTRarity, RarityType } from "./types"
import { isDevelopmentStage } from "../../constants/appStages"

export const MAX_NFT_BATCH_CLAIM_COUNT = 10

export const NFT_MULTICLAIM_CONTRACT_ADDRESS = "EQAOr5zueJ9LxgNPHpYyH-E4ILf1lTuOkE8LK48Qr5wfcun-"

const NFT_COLLECTION_IMAGES_PUMA = [Puma0, Puma1, Puma2, Puma3, Puma4, Puma5, Puma6]

const NFT_COLLECTION_IMAGES_CACTUS = [Cactus0, Cactus1, Cactus2, Cactus3, Cactus4, Cactus5, Cactus6]

export const NFT_COLLECTION_IMAGES = {
	[CollectionNames.Puma]: NFT_COLLECTION_IMAGES_PUMA,
	[CollectionNames.Cactus]: NFT_COLLECTION_IMAGES_CACTUS,
}

export const NFT_SLIDER_IMAGES_PUMA = [
	{
		element: <img src={Puma0} alt="nft" />,
		id: Puma0,
	},
	{
		element: <img src={Puma1} alt="nft" />,
		id: Puma1,
	},
	{
		element: <img src={Puma2} alt="nft" />,
		id: Puma2,
	},
	{
		element: <img src={Puma3} alt="nft" />,
		id: Puma3,
	},
	{
		element: <img src={Puma4} alt="nft" />,
		id: Puma4,
	},
	{
		element: <img src={Puma5} alt="nft" />,
		id: Puma5,
	},
	{
		element: <img src={Puma6} alt="nft" />,
		id: Puma6,
	},
	{
		element: <img src={Puma7} alt="nft" />,
		id: Puma7,
	},
	{
		element: <img src={Puma8} alt="nft" />,
		id: Puma8,
	},
	{
		element: <img src={Puma9} alt="nft" />,
		id: Puma9,
	},
]

export const NFT_SLIDER_IMAGES_CACTUS = [
	{
		element: <img src={Cactus0} alt="nft" />,
		id: Cactus0,
	},
	{
		element: <img src={Cactus1} alt="nft" />,
		id: Cactus1,
	},
	{
		element: <img src={Cactus2} alt="nft" />,
		id: Cactus2,
	},
	{
		element: <img src={Cactus3} alt="nft" />,
		id: Cactus3,
	},
	{
		element: <img src={Cactus4} alt="nft" />,
		id: Cactus4,
	},
	{
		element: <img src={Cactus5} alt="nft" />,
		id: Cactus5,
	},
	{
		element: <img src={Cactus6} alt="nft" />,
		id: Cactus6,
	},
	{
		element: <img src={Cactus7} alt="nft" />,
		id: Cactus7,
	},
	{
		element: <img src={Cactus8} alt="nft" />,
		id: Cactus8,
	},
	{
		element: <img src={Cactus9} alt="nft" />,
		id: Cactus9,
	},
]

export const NFT_SLIDER_IMAGES = {
	[CollectionNames.Puma]: NFT_SLIDER_IMAGES_PUMA,
	[CollectionNames.Cactus]: NFT_SLIDER_IMAGES_CACTUS,
}

export const COLLECTIONS_ID = {
	puma: isDevelopmentStage ? "1a2a8620-a78f-49a2-90a3-d175245042d8" : "",
	cactus: isDevelopmentStage ? "0c30e308-a6fa-4a51-8f92-31b1ac75d50d" : "",
}

export const nftRarityPriority = {
	[RarityType.common]: { value: 1, nftRarity: NFTRarity.common },
	[RarityType.uncommon]: { value: 2, nftRarity: NFTRarity.uncommon },
	[RarityType.rare]: { value: 3, nftRarity: NFTRarity.rare },
	[RarityType.epic]: { value: 4, nftRarity: NFTRarity.epic },
	[RarityType.legendary]: { value: 5, nftRarity: NFTRarity.legendary },
}
