import cn from "classnames"
import { FC, useEffect } from "react"
import { IconTelegram } from "../../../../newComponents/Icons/IconTelegram"

interface ILoginTelegramProps {
	className?: string
	disabled?: boolean
}

export const LoginTelegram: FC<ILoginTelegramProps> = ({ className, disabled }) => {
	useEffect(() => {
		const script = document.createElement("script")
		script.src = "https://telegram.org/js/telegram-widget.js"
		script.async = true

		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	const handleTelegramConnect = () => {
		const origin = process.env.REACT_APP_TELEGRAM_CONNECTION_URL

		window.open(origin + "?auth=tg", "Telegram", "popup,width=350,height=250")
	}

	return (
		<button
			className={cn("login-telegram", className)}
			onClick={disabled ? undefined : handleTelegramConnect}
			disabled={disabled}
		>
			<IconTelegram />
		</button>
	)
}
