export type NFTRarityDisplayProps = {
	color: string
	titleI18key: string
}

export enum CollectionNames {
	Cactus = "cactus",
	Puma = "puma",
}

export enum NFTRarity {
	legendary = "legendary",
	epic = "epic",
	rare = "rare",
	uncommon = "uncommon",
	common = "common",
}

export enum ClaimStatus {
	started = "STARTED",
	processing = "PROCESSING",
	finished = "FINISHED",
}

export enum RarityType {
	common = "puma_common",
	uncommon = "puma_uncommon",
	rare = "puma_rare",
	epic = "puma_epic",
	legendary = "puma_legendary",
}

export type EarlyNftType = {
	id: string
	rarity: NFTRarity
	nftIndex: number
	source: "clicker" | "dashboard"
	url: string
}

export type NftCollection = {
	id: string
	key: CollectionNames
	name: string
	description: string
	address: string
	price: number
	isActive: boolean
	createdAt: string
	updatedAt: string
	userNftsCount?: number
}

export type NftCollectionActiveResponse = NftCollection[]

export type NftPropsRaw = {
	id: string
	user?: {}
	userId?: string
	clickerUserId?: string
	collection?: {}
	collectionId?: string
	index?: number
	url: string
	rarity?: RarityType
	signature?: string
	claimStatus: ClaimStatus
	createdAt: string
	updatedAt: string
}

export type ClaimsData = {
	claims: NftPropsRaw[]
	currentProcessingClaims: NftPropsRaw[]
	rarestClaimedNft: NftPropsRaw
}

export type UserCollectionsAndNftsRaw = {
	collection: NftCollection
	claimsData?: ClaimsData
	discountRate?: number
}

export type UserNftsResponse = UserCollectionsAndNftsRaw[]

export type GeneratedNftType = {
	id: string
	index?: number
	url: string
	claimStatus: ClaimStatus
	rarity?: RarityType
	createdAt: string
	updatedAt: string
}

export type GenerateNftPaymentDetails = {
	signature: string
	totalAmount: number
}

export type GenerateNftClaimDetails = {
	user: {}
	userId: string
	clickerUserId: string
	collection: {}
	collectionId: string
	claims: GeneratedNftType[]
}

export type GenerateNftResponse = {
	paymentDetails: GenerateNftPaymentDetails
	claims: GeneratedNftType[]
	collectionId: string
}

export type GenerateSignatureResponse = {
	signature: string
	totalAmount: number
}

export type SetClaimToProcessingResponse = {
	userId: string
	collectionId: string
	claims: GeneratedNftType[]
}
