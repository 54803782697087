import { useTranslation } from "react-i18next"
import { Button } from "../../../../../components/Buttons/Button"
import { FormEvent, useEffect, useState } from "react"
import { InputField, InputFieldErrors } from "../../../../../newComponents/Input"
import accountLinkingService from "../../../../login/services/accountLinkingService"
import { useAccountLinkingStore } from "../../../../login/store/accountLinkingStore"
import { useLoginStore } from "../../../../login/store/loginStore"

export const AccountLinkingStep1 = () => {
	const { t } = useTranslation()
	const { email, emailErrorMessage, otpRequestLoading, otpRequested } = useAccountLinkingStore()
	const { otpError } = useLoginStore()

	const [submitDisabled, setSubmitDisabled] = useState(false)
	const [emailInputStatus, setEmailInputStatus] = useState<InputFieldErrors | null>(null)

	useEffect(() => {
		if (otpRequested) {
			accountLinkingService.accountLinkingStepNext()
		}
	}, [otpRequested])

	useEffect(() => {
		if (emailErrorMessage || otpError || !email) {
			setSubmitDisabled(true)
			setEmailInputStatus(InputFieldErrors.ERROR)
		} else {
			setSubmitDisabled(false)
			setEmailInputStatus(null)
		}
	}, [emailErrorMessage, otpError, email])

	const onEmailChange = (value: string) => {
		if (emailErrorMessage || otpError) {
			accountLinkingService.clearEmailError()
		}
		accountLinkingService.setEmail(value)
	}

	const onSubmitEmail = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		await accountLinkingService.accountLinkingCheckAndGetOtp(email)
	}

	return (
		<form id="link-account-form" className="account-email-linking-modal" onSubmit={onSubmitEmail}>
			<InputField
				label={t("auth.signup.input.label")}
				type="email"
				placeholder={t("login.email")}
				onChange={onEmailChange}
				status={emailInputStatus}
				message={emailErrorMessage || otpError}
			/>
			<Button
				form="link-account-form"
				isLoading={otpRequestLoading}
				variant="secondary"
				size="large"
				disabled={submitDisabled}
				isUppercased
			>
				{t("auth.linkAccount.step1.button")}
			</Button>
		</form>
	)
}
