import {create, useStore} from "zustand";
import {
  AccountConnectResponse,
  AccountCreationSteps,
  AccountCreationType,
  AccountExistCreationSteps,
  AccountLinkingSteps,
  ChooseAccountForLinkingResponse1,
  SelectedAccountToLink
} from "../types";

export interface IAccountLinkingStore {
  otpRequestLoading: boolean
  otpRequested: boolean

  connectAccountLoading: boolean

  getAccountDataLoading: boolean

  chooseAccountLoading: boolean

  emailAccountCreationLoading: boolean
  choosedAccountResult: ChooseAccountForLinkingResponse1 | null

  isEmailExists: boolean
  emailErrorMessage: string | null
  email: string

  isSignUpTypeModalOpen: boolean;

  isNewRegistrationWithTelegram: boolean;

  isAccountLinkingModalOpen: boolean;
  accountLinkingStep: AccountLinkingSteps;

  isAccountCreationModalOpen: boolean;
  isAccountCreationEmailExist: boolean;
  accountCreationSteps: AccountCreationSteps;
  accountCreationExistSteps: AccountExistCreationSteps;
  accountCreationType: AccountCreationType;

  accountToLinking: AccountConnectResponse | null;
  selectedAccountToLink: SelectedAccountToLink;
}

export const accountLinkingStore = create<IAccountLinkingStore>(() => ({
  otpRequestLoading: false,
  otpRequested: false,

  isEmailExists: false,
  emailErrorMessage: null,
  email: "",

  connectAccountLoading: false,

  getAccountDataLoading: false,

  chooseAccountLoading: false,
  choosedAccountResult: null,

  emailAccountCreationLoading: false,

  /**
   * For login screen
   */
  isSignUpTypeModalOpen: false,
  /**
   * Appears on profile page when first time register thu telegram
   */
  isNewRegistrationWithTelegram: false,
  /**
   * Email Account linking if on isNewRegistrationWithTelegram choosen "Yes"
   */
  isAccountLinkingModalOpen: false,
  accountLinkingStep: "email",

  /**
   * Opens from profile edit page "Connect email" button, opens modal with email input
   */
  isAccountCreationModalOpen: false,
  /**
   * true if after isAccountCreationModalOpen we got email in database
   */
  isAccountCreationEmailExist: false,
  accountCreationType: "emailStep",
  accountCreationExistSteps: "otp",
  /**
   * steps keys for new email linking to existing telegram account
   */
  accountCreationSteps: "otp",

  accountToLinking: null,
  selectedAccountToLink: null,
}));

export const useAccountLinkingStore = () => useStore(accountLinkingStore);
