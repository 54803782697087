import { useTranslation } from "react-i18next"
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution"
import { useParams } from "react-router-dom"
import { useMemo } from "react"

import { charactersStore } from "../../store"
import { nftRarityPriority } from "../../constants"

import { EarlyNftCard } from "./EarlyNftCard"
import { TonWalletDisplay } from "./TonWalletDisplay"
import { CollectionNames, NFTRarity } from "../../types"

import css from "./ClaimedEarly.module.scss"

export const ClaimedEarly = () => {
	const { t } = useTranslation()
	const { collectionId } = useParams()

	const isMobile = useIsMobileResolution(576)
	const { earlyNft, selectedCollection, userCollectionsAndNfts } = charactersStore()
	const isCactusCollections = selectedCollection?.collection.key === CollectionNames.Cactus

	const discountRate =
		userCollectionsAndNfts?.find(el => el.collection.id === selectedCollection?.collection.id)?.discountRate || 0

	const pumaRarity = useMemo(() => {
		if (userCollectionsAndNfts && collectionId) {
			return userCollectionsAndNfts
				?.find(el => el.collection.key === CollectionNames.Puma)
				?.claimsData?.claims.reduce(
					(p, c) => {
						if (c.rarity) {
							return nftRarityPriority[c.rarity].value > p.value
								? {
										value: nftRarityPriority[c.rarity].value,
										nftRarity: nftRarityPriority[c.rarity].nftRarity,
										imageUrl: c.url,
									}
								: p
						}
						return p
					},
					{ value: 0, nftRarity: NFTRarity.common, imageUrl: "" },
				)
		}
		return undefined
	}, [collectionId, userCollectionsAndNfts])

	return (
		<div className={css.wrapper}>
			<div className={`${css.wrapper_scroll} `}>
				<div className={`${css.wrapper_inner} custom-scrollbar-gray`}>
					<div className={css.shine} />
					{collectionId && isCactusCollections ? (
						<>
							<h3>{t("claim.characters.userNft.title")}</h3>
							<p>{t("claim.characters.userNft.description")}</p>
							<EarlyNftCard
								title={`${t("claim.characters.userNft.discount")} ${discountRate}%`}
								rarity={pumaRarity && pumaRarity.value > 0 ? pumaRarity?.nftRarity : undefined}
								avatarUrl={pumaRarity?.imageUrl}
								className={css.mb}
								avatarType="puma"
							/>
							<div />
							{isMobile ? null : <TonWalletDisplay />}
						</>
					) : (
						<>
							<h3>{t("claim.characters.early.title")}</h3>
							<p>{t("claim.characters.early.description")}</p>
							<EarlyNftCard
								title={t("claim.characters.early.botnft.title")}
								rarity={earlyNft?.clicker?.rarity}
								avatarUrl={earlyNft?.clicker?.url}
								className={css.mb}
								avatarType="bot"
							/>
							<EarlyNftCard
								title={t("claim.characters.early.dashboardnft.title")}
								rarity={earlyNft?.dashboard?.rarity}
								avatarUrl={earlyNft?.dashboard?.url}
								className={css.mb}
								avatarType="dashboard"
							/>
							{isMobile ? null : <TonWalletDisplay />}
						</>
					)}
				</div>
			</div>
		</div>
	)
}
