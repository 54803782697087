import { Abi, ReadContractParameters, createPublicClient, http } from "viem"
import { APP_CHAIN, stakingDecimals } from "../constants"
import { CONTRACTS_DATA } from "./contracts"
import { toHRNumberFloat, toBn, fromHRToBN, ceilNumber } from "../../../utils/bigNumber"
import { getRewardPerDay } from "../../../utils/time"
import erc20Abi from "./contracts/erc20/Erc20ABI.json"
import { CurrentEpochDataResponseType, CurrentEpochDataType } from "../types"
import { stakingStore } from "../store"

export const publicClient = createPublicClient({
	chain: APP_CHAIN,
	transport: http(),
})

const prepareContractError: <T extends ReadContractParameters<Abi | unknown[], string, readonly unknown[]>>(
	contractRequestParams: T,
) => Promise<unknown> = async contractRequestParams => {
	let data
	try {
		const wagmiClient = stakingStore.getState().publicClient //TODO remove external functions from store
		const client = wagmiClient || publicClient

		data = await client.readContract(contractRequestParams)
	} catch (e) {
		console.log("Contract read error: function name - ", contractRequestParams.functionName, " Error: ", e)
	}
	return data
}

export const contractReadService = (accountAddress: `0x${string}`) => {
	return {
		async getStakedBalance() {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.staking.address,
				abi: CONTRACTS_DATA.staking.abi,
				functionName: "balanceOf",
				args: [accountAddress],
			})

			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async getTicketsPerDay(epoch: string) {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.calculator.address,
				abi: CONTRACTS_DATA.calculator.abi,
				functionName: "rewardPerSecond",
				args: [epoch, accountAddress],
			})
			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(getRewardPerDay(toBn(`${data}`)), +stakingDecimals)),
			}
		},
		async getTokenBalance() {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.token.address,
				abi: CONTRACTS_DATA.staking.abi,
				functionName: "balanceOf",
				args: [accountAddress],
			})
			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async getAllowance() {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.token.address,
				abi: erc20Abi,
				functionName: "allowance",
				args: [accountAddress, CONTRACTS_DATA.staking.address],
			})

			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async getEpoch() {
			let newEpoch: CurrentEpochDataType = {
				epochIndex: "-1",
				epoch: {
					epochEndTimestamp: "-1",
					epochStartTimestamp: "-1",
					pointsPer1MShares: "0",
				},
				isValid: false,
			}

			const currentEpoch = (await prepareContractError({
				address: CONTRACTS_DATA.calculator.address,
				abi: CONTRACTS_DATA.calculator.abi,
				functionName: "currentEpoch",
			})) as CurrentEpochDataResponseType

			newEpoch = {
				epochIndex: toBn(currentEpoch[0]).toString(10),
				epoch: currentEpoch[1],
				isValid: currentEpoch[2],
			}

			if (!newEpoch.isValid) {
				prepareContractError({
					address: CONTRACTS_DATA.calculator.address,
					abi: CONTRACTS_DATA.calculator.abi,
					functionName: "epochs",
					args: [0],
				}).then((res: any) => {
					newEpoch = {
						...newEpoch,
						epoch: {
							epochStartTimestamp: res[0],
							epochEndTimestamp: res[1],
							pointsPer1MShares: res[2],
						},
					} as CurrentEpochDataType
				})
			}
			return newEpoch
		},
		async getEpochByIndex(index: number) {
			let newEpoch: CurrentEpochDataType = {
				epochIndex: "-1",
				epoch: {
					epochEndTimestamp: "-1",
					epochStartTimestamp: "-1",
					pointsPer1MShares: "0",
				},
				isValid: false,
			}

			const response = (await prepareContractError({
				address: CONTRACTS_DATA.calculator.address,
				abi: CONTRACTS_DATA.calculator.abi,
				functionName: "epochs",
				args: [index],
			})) as any

			return {
				...newEpoch,
				epochIndex: index.toString(),
				epoch: {
					epochStartTimestamp: response[0],
					epochEndTimestamp: response[1],
					pointsPer1MShares: response[2],
				},
			}
		},

		async getStakerStakeCount() {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.staking.address,
				abi: CONTRACTS_DATA.staking.abi,
				functionName: "stakerStakeCount",
				args: [accountAddress],
			})
			return {
				raw: data,
				formatted: toBn(`${data}`).toNumber(),
			}
		},
		async getMaxBps() {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.staking.address,
				abi: CONTRACTS_DATA.staking.abi,
				functionName: "MAX_BPS",
			})
			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async getPenaltyDays() {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.staking.address,
				abi: CONTRACTS_DATA.staking.abi,
				functionName: "penaltyDays",
			})
			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async getRewardByStaker(epochIndex: number | string) {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.calculator.address,
				abi: CONTRACTS_DATA.calculator.abi,
				functionName: "rewardByStaker",
				args: [epochIndex, accountAddress],
			})
			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async getIsClaimed(index: number, epoch: number) {
			const data = (await prepareContractError({
				address: CONTRACTS_DATA.merkleDistributor.addresses![`MERKLE_DISTRIBUTOR${epoch - 1}`],
				abi: CONTRACTS_DATA.merkleDistributor.abi,
				functionName: "isClaimed",
				args: [index],
			})) as boolean | string

			return {
				raw: data,
				formatted: typeof data === "boolean" ? data : data === "true",
			}
		},
		async calculateShares(value: string) {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.staking.address,
				abi: CONTRACTS_DATA.staking.abi,
				functionName: "calculateShares", //(uint256)",
				args: [fromHRToBN(+value, stakingDecimals)],
			})
			return {
				raw: data,
				formatted: toBn(toHRNumberFloat(toBn(`${data}`), +stakingDecimals)),
			}
		},
		async calculateIncome(epochIndex: string, shares: string) {
			const data = await prepareContractError({
				address: CONTRACTS_DATA.calculator.address,
				abi: CONTRACTS_DATA.calculator.abi,
				functionName: "rewardPerSecond", //(uint256,uint256)",
				args: [epochIndex ?? 0, shares],
			})
			const res = toHRNumberFloat(getRewardPerDay(toBn(`${data}`)), stakingDecimals)
			const ceilRes = ceilNumber(+res)
			return {
				raw: data,
				formatted: ceilRes,
			}
		},
	}
}
