import { AxiosError } from "axios"

export const getErrorMessage = (error: unknown) => {
	const typedError = error as AxiosError<{
		code: string
		message: string | Array<string>
	}>

	if (typedError.response?.data.message) {
		if (typeof typedError.response.data.message === "string") {
			return typedError.response.data.message
		} else {
			return typedError.response.data.message.join(", ")
		}
	}
	return ""
}
