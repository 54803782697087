import { create, useStore } from "zustand"

import {
	ClaimStatus,
	EarlyNftType,
	GeneratedNftType,
	GenerateNftResponse,
	NftPropsRaw,
	UserCollectionsAndNftsRaw,
} from "./types"

import publicNavigate from "../../services/PublicNavigate"

import { AppRoutes } from "../../constants/routes"

import { MAX_NFT_BATCH_CLAIM_COUNT } from "./constants"

export type INftStore = {
	loading: boolean

	isUserNftsLoading: boolean

	userCollectionsAndNfts: UserCollectionsAndNftsRaw[] | null
	selectedCollection: UserCollectionsAndNftsRaw | null

	isAbleToNewClaim: boolean
	isAbleToContinueClaim: boolean

	claimedNftItems: GeneratedNftType[] | null

	batchClaimCount: number
	batchClaimGeneratedItems: GenerateNftResponse | null

	//modals state
	selectedNftToBuy: GeneratedNftType[] | null
	selectedNftToShow: Partial<NftPropsRaw> | null
	isNftInfoModalOpen: boolean

	isNftCongratsModalOpen: boolean

	isBuyingModalOpen: boolean

	earlyNft: {
		dashboard: EarlyNftType | null
		clicker: EarlyNftType | null
	} | null

	actions: {
		setSelectedCollectionById: (value: string) => void

		setIsAbleToNewClaim: (value: boolean) => void
		setIsAbleToContinueClaim: (value: boolean) => void

		batchClaimCountPlus: () => void
		batchClaimCountMinus: () => void
		setBatchClaimCount: (value: number) => void

		selectNft: (nftInfo: Partial<NftPropsRaw>) => void

		setIsNftCongratsModalOpen: (value: boolean) => void
		closeCongratsModalAfterBatchBuy: () => void
		closeCongratsModalBeforeBatchBuy: () => void

		setIsBuyingModalOpen: (value: boolean) => void
		closeBuyingModalAfterBuy: () => void
		closeBuyingModalBeforeBuy: () => void

		setIsNftInfoModalOpen: (value: boolean) => void
		closeNftInfoModalAfterBuy: () => void
		closeNftInfoModalBeforeBuy: () => void

		setSelectedNftsToBuy: (items: NftPropsRaw[]) => void
	}
}

export const charactersStore = create<INftStore>((set, get) => ({
	loading: false,

	isUserNftsLoading: false,
	userCollectionsAndNfts: null,
	selectedCollection: null,

	isAbleToNewClaim: false,
	isAbleToContinueClaim: false,

	claimedNftItems: null,

	batchClaimCount: 1,
	batchClaimGeneratedItems: null,

	selectedNftToBuy: null,
	selectedNftToShow: null,
	isNftInfoModalOpen: false,
	isNftCongratsModalOpen: false,

	isBuyingModalOpen: false,

	earlyNft: null,

	actions: {
		setSelectedCollectionById: collectionId => {
			const userCollectionsObj = get().userCollectionsAndNfts?.find(el => {
				return el.collection?.id === collectionId
			})
			if (userCollectionsObj) {
				const { claimsData } = userCollectionsObj
				if (claimsData?.currentProcessingClaims?.length) {
					const { currentProcessingClaims } = claimsData
					set({
						selectedCollection: userCollectionsObj,
						isAbleToNewClaim: currentProcessingClaims.some(el => el.claimStatus === ClaimStatus.finished),
						isAbleToContinueClaim: currentProcessingClaims.some(el => el.claimStatus === ClaimStatus.started),
					})
				} else {
					set({
						selectedCollection: userCollectionsObj,
						isAbleToNewClaim: true,
						isAbleToContinueClaim: false,
					})
				}
			} else {
				publicNavigate.navigate(AppRoutes.characters)
			}
		},
		setIsAbleToNewClaim: (value: boolean) => {
			set({ isAbleToNewClaim: value })
		},
		setIsAbleToContinueClaim: (value: boolean) => {
			set({ isAbleToContinueClaim: value })
		},

		batchClaimCountPlus: () => {
			const currentCount = get().batchClaimCount
			set({ batchClaimCount: currentCount >= MAX_NFT_BATCH_CLAIM_COUNT ? currentCount : currentCount + 1 })
		},
		batchClaimCountMinus: () => {
			const currentCount = get().batchClaimCount
			if (currentCount > 1) {
				set({ batchClaimCount: currentCount - 1 })
			}
		},
		setBatchClaimCount: (value: number) => {
			set({ batchClaimCount: value || 1 })
		},
		selectNft: (nftInfo: Partial<NftPropsRaw>) => {
			set({ selectedNftToShow: nftInfo })
		},

		setIsNftCongratsModalOpen: (value: boolean) => {
			set({
				isNftCongratsModalOpen: value,
			})
		},
		closeCongratsModalAfterBatchBuy: () => {
			set({
				isNftCongratsModalOpen: false,
				claimedNftItems: null,
				batchClaimCount: 1,
				batchClaimGeneratedItems: null,
				isAbleToContinueClaim: false,
				isAbleToNewClaim: false,
			})
		},
		closeCongratsModalBeforeBatchBuy: () => {
			set({
				isNftCongratsModalOpen: false,
				batchClaimCount: 1,
				batchClaimGeneratedItems: null,
				isAbleToContinueClaim: true,
				isAbleToNewClaim: false,
			})
		},

		setIsBuyingModalOpen: (value: boolean) => {
			set({ isBuyingModalOpen: value })
		},
		closeBuyingModalBeforeBuy: () => {
			set({
				isBuyingModalOpen: false,
			})
		},
		closeBuyingModalAfterBuy: () => {
			set({ isBuyingModalOpen: false })
		},

		setIsNftInfoModalOpen: (value: boolean) => {
			set({ isNftInfoModalOpen: value })
		},
		closeNftInfoModalAfterBuy: () => {},
		closeNftInfoModalBeforeBuy: () => {},
		setSelectedNftsToBuy: items => {
			set({ selectedNftToBuy: items })
		},
	},
}))

export const useCharactersStore = () => useStore(charactersStore)
