import {Trans, useTranslation} from "react-i18next";
import {ConnectEmailMainModalWrapper} from "../MainModalComponent/ConnectEmailMainModalWrapper";
import "./IsHaveEmailAccountModal.scss";
import {Button} from "../../../../../components/Buttons/Button";
import {useAccountLinkingStore} from "../../../../login/store/accountLinkingStore";
import accountLinkingService from "../../../../login/services/accountLinkingService";

/**
 * Uppears only first time when we have new registration thru telegram
 */

export const IsHaveEmailAccountModal = () => {
  const {t} = useTranslation()
  const {isNewRegistrationWithTelegram} = useAccountLinkingStore()

  const handleNo = () => {
    accountLinkingService.isHaveEmailAccount(false)
  }

  const handleYes = () => {
    accountLinkingService.isHaveEmailAccount(true)
  }

  return <ConnectEmailMainModalWrapper
    open={isNewRegistrationWithTelegram}
    onClose={() => {
    }}
    title={t("auth.linkAccount.step0.title")}
    text={
      <Trans
        i18nKey={"auth.linkAccount.step0.text"}
        components={{white: <span/>}}
      />
    }
    steps={{currentStep: 1, totalSteps: 3}}
  >
    <div className="is-have-email-account__buttons">
      <Button
        onClick={handleNo}
        size="large"
        isUppercased
      >{t("buttons.no")}</Button>
      <Button
        onClick={handleYes}
        size="large"
        variant="secondary"
        isUppercased
      >{t("buttons.yes")}</Button>
    </div>
  </ConnectEmailMainModalWrapper>
}
