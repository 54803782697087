import { t } from "i18next"
import { Loader } from "../../../../components/Loader"
import { usePetsStore } from "../../store/petsStore"
import css from "./PetAvatar.module.scss"
import { useMemo, useState } from "react"
import petPlaceholder from "../../../../assets/images/bot-placeholder.png"
import { useUserStore } from "../../../../services/user/store"
import { IPet, IUserPet } from "../../types"
import { Card } from "../../../../components/Card/Card"
import { EditPetNameModal } from "../EditPetNameModal"
import { petsService } from "../../service/service"
import { EditIcon } from "../../../../assets/iconComponents/new_design_icons/EditIcon"
import ProgressBar from "../../../../components/TopBar/components/MiniProfile/Progress/ProgressBar/ProgressBar"
import { LvlUpButton } from "../Buttons/LvlUpButton"
import { Button } from "../../../../components/Buttons/Button"
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution"
import classNames from "classnames"

export const PetAvatar = () => {
	const isMobile = useIsMobileResolution()
	const [editNameModalOpen, setEditNameModalOpen] = useState(false)
	const user = useUserStore()
	const { pets, loading, changingPet } = usePetsStore()
	const userPet = useMemo(() => {
		const userPet = user.user?.pet

		const originalPet = pets.find(item => item.id === user.user?.pet?.petId)

		if (userPet && originalPet) {
			return { ...originalPet, ...userPet } as IPet & IUserPet
		}
	}, [pets, user])

	const isEvolutionAvailable = useMemo(() => {
		if (userPet) {
			const isNextEvolutionBought = pets.some(
				({ rarity, race }) => race === userPet.race && rarity === userPet.rarity + 1,
			)

			const isEvolutionAvailable = !isNextEvolutionBought && (userPet.userPet?.level || 1) / 10 >= 1

			return isEvolutionAvailable
		}
		return false
	}, [userPet, pets])

	const petDescription = userPet?.descriptionLocalizationKey
		? t(userPet?.descriptionLocalizationKey)
		: userPet?.description || ""

	const petLvl = userPet?.level
	const isAbleToLvlUp = petLvl === 20 && !isEvolutionAvailable

	if (loading) {
		return (
			<div className={css.loaderContainer}>
				<Loader />
			</div>
		)
	}

	return (
		<div className={classNames(css.container, !isMobile && css.hoverActive)}>
			<div className={css.mask} />
			<div className={css.avatar_container}>
				<div className={css.shine} />
				<div className={css.centered} onClick={() => petsService.setIsPetsModalOpen(true)}>
					<span>{t("profile.avatar.change")}</span>
					<EditIcon />
				</div>
				<img
					src={userPet ? userPet.imageUrl : petPlaceholder}
					alt="avatar"
					onClick={() => petsService.setIsPetsModalOpen(true)}
				/>
			</div>
			{userPet && (
				<Card className={css.info_container}>
					<div>
						{user.user?.pet
							? (user.user?.pet?.customName || userPet?.name || user) && (
									<div className={css.header}>
										<h2 className={css.petname}>
											{user.user?.pet?.customName || userPet?.name}
											<EditIcon onClick={() => setEditNameModalOpen(true)} className={css.editIcon} />
										</h2>
										<div className={css.levelProgress}>
											<ProgressBar color="#B364D8" className="progress__bar" progress={(userPet.level * 100) / 20} />
											<p className="progress__text">
												{userPet.level} <span>LVL</span>
											</p>
										</div>
									</div>
								)
							: null}
						{user.user?.pet ? <div className={css.petsDescription}>{petDescription}</div> : null}
					</div>
					<div className={css.petActions}>
						<Button
							onClick={() => petsService.setIsPetsModalOpen(true)}
							isUppercased
							variant="secondary"
							decoration="outlined"
							size="large"
						>
							{t("pets.buttons.edit-bot")}
						</Button>
						{isAbleToLvlUp ? (
							<button className={css.petInfoBtn}>
								<div className={css.btnBody} style={{ color: "#FF3D3D" }}>
									{t("buttons.maxLvl")}
								</div>
							</button>
						) : (
							<LvlUpButton
								onClick={() => petsService.setIsProgressionModalOpened(true)}
								isBought={true}
								loading={loading}
								selectedPet={userPet}
								currentPet={userPet}
							/>
						)}
					</div>
				</Card>
			)}

			<EditPetNameModal
				open={editNameModalOpen}
				name={user.user?.pet?.customName || userPet?.name}
				loading={changingPet}
				handleSave={({ customName }) =>
					petsService.changePetName({
						customName,
						userPetId: userPet?.userPet?.id!,
					})
				}
				onClose={() => setEditNameModalOpen(false)}
			/>
		</div>
	)
}
