import { NFTRarity, NFTRarityDisplayProps } from "../pages/characters/types"

export const NFT_RARITY_DICT: Record<NFTRarity, NFTRarityDisplayProps> = {
	[NFTRarity.common]: {
		color: "#CCCCCC",
		titleI18key: "claim.characters.rarity.common",
	},
	[NFTRarity.uncommon]: {
		color: "#83D987",
		titleI18key: "claim.characters.rarity.uncommon",
	},
	[NFTRarity.epic]: {
		color: "#C64EFF",
		titleI18key: "claim.characters.rarity.epic",
	},
	[NFTRarity.legendary]: {
		color: "#EEA12B",
		titleI18key: "claim.characters.rarity.legendary",
	},
	[NFTRarity.rare]: {
		color: "#2EB4FF",
		titleI18key: "claim.characters.rarity.rare",
	},
}
