import { useTranslation } from "react-i18next"
import { ModalWithHeader } from "../../../../components/Modals/ModalWithHeader"
import { Text } from "../../../../newComponents/Text/Text"
import "./SignUpTypeSelectModal.scss"
import { SignupCard } from "./SignupCard"
import { useAccountLinkingStore } from "../../store/accountLinkingStore"
import accountLinkingService from "../../services/accountLinkingService"



export const SignUpTypeSelectModal = () => {
  const { t } = useTranslation()
  const { isSignUpTypeModalOpen } = useAccountLinkingStore()

  return <ModalWithHeader
    open={isSignUpTypeModalOpen}
    onClose={() => accountLinkingService.setIsSignUpTypeModalOpen(false)}
    title={t("auth.signup.title")}
  >
    <>
      <Text className="signup-type-select__text">
        {t("auth.signup.newUser.text")}
      </Text>
      <div className="signup-type-select__variants">
        <SignupCard type="google" />
        <SignupCard type="telegram"/>
      </div>
    </>
  </ModalWithHeader>
}
