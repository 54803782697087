import styles from "./NftCollectionShowCase.module.scss"
import { NFT_COLLECTION_IMAGES } from "../../constants"
import { NftCollection } from "../../types"

type INftCollectionShowCaseProps = {
	collection: NftCollection
}

export const NftCollectionShowCase: React.FC<INftCollectionShowCaseProps> = ({ collection }) => {
	return (
		<div className={styles.wrapper}>
			{NFT_COLLECTION_IMAGES[collection.key].map(image => (
				<div key={image}>
					<div className={styles.img} style={{ backgroundImage: `url(${image})` }} />
				</div>
			))}
		</div>
	)
}
