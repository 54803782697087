import { AvatarCard } from "./AvatarCard"
import { IAvatarInernal, useAvatarsStore } from "./store"
import { useTranslation } from "react-i18next"
import styles from "./AvatarsList.module.scss"
import { useWindowWidth } from "../../../../../hooks/useWindowWidth"
import { calculateDropdownElementIndex } from "./avatar.helper"
import { useEffect, useState } from "react"
import { Button } from "../../../../../components/Buttons/Button"
import { BuyAvatarModal } from "./BuyModal"
import avatarsService from "./service"
import { userProfileService } from "../../../service/service"

export const AvatarsList = ({
	selectedAvatarToEditId,
	setSelectedAvatarToEditId,
	currentAvatar,
	selectedAvatarID,
}: {
	selectedAvatarToEditId?: string
	setSelectedAvatarToEditId: (avatar: string | undefined) => void
	currentAvatar: string
	selectedAvatarID: string
}) => {
	const { t } = useTranslation()
	const [selectedAvatar, setSelectedAvatar] = useState<IAvatarInernal | null>(null)
	const [isBuyModalOpen, setIsBuyModalOpen] = useState(false)
	const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<number>(-1)
	const { avatars } = useAvatarsStore()
	const userAvatarsCount = avatars.filter(el => !!el.userAvatar).length
	const windowWidth = useWindowWidth()

	useEffect(() => {
		setSelectedAvatarIndex(avatars.findIndex(avatar => avatar.id === selectedAvatarID))
		if (selectedAvatarID) {
			setSelectedAvatar(avatars.find(a => a.id === selectedAvatarID) ?? null)
			setSelectedAvatarToEditId(currentAvatar)
		}
	}, [avatars, selectedAvatarID, selectedAvatarToEditId, setSelectedAvatarToEditId, currentAvatar])

	const handleBuy = async () => {
		await avatarsService.buyAvatar(selectedAvatar?.id || "")
		setIsBuyModalOpen(false)
	}

	return (
		<div className={`${styles.container}`}>
			<div className={styles.header}>
				<div className="mb-4">{`${userAvatarsCount}/${avatars.length} ${t("profile.avatars.available")}`}</div>
			</div>

			<div className={`${styles.list_placeholder}`}>
				{avatars.map((avatar, index) => {
					return (
						<>
							<AvatarCard
								key={avatar.id}
								onClick={() => {
									userProfileService.setSelectedAvatarId(avatar.id)
								}}
								userAvatar={avatar.userAvatar}
								avatar={avatar}
								isCurrent={currentAvatar === avatar.id}
								isSelected={selectedAvatarID === avatar.id}
								isNft={avatar.isNft}
							/>
							{calculateDropdownElementIndex(selectedAvatarIndex, windowWidth, avatars.length) === index &&
								selectedAvatar &&
								(() => {
									const { description, descriptionLocalizationKey, name, nameLocalizationKey, userAvatar, id, isNft } =
										selectedAvatar
									return (
										<div key={id + "dropdown"} className={styles.mobile_avatar_info}>
											<h3>{isNft ? name : t(nameLocalizationKey || "") || name}</h3>
											<span>{t("profile.avatars.info.subtitle")}</span>
											<p className="custom-scrollbar">
												{isNft ? description : t(descriptionLocalizationKey || "") || description}
											</p>
											<Button
												variant="secondary"
												size="large"
												decoration="outlined"
												isUppercased
												style={!userAvatar ? { borderColor: "#83D987" } : {}}
												disabled={selectedAvatarToEditId === id}
												onClick={() => {
													if (!!userAvatar) {
														id && setSelectedAvatarToEditId(id)
													} else {
														setIsBuyModalOpen(true)
													}
												}}
											>
												{!!userAvatar &&
													(currentAvatar === id
														? t("buttons.chosen-avatar")
														: t(
																"profile.avatars.choose",
															))
													// selectedAvatarToEditId === undefined
													//   ? currentAvatar === id
													//   : selectedAvatarToEditId === id
													//     ? t("buttons.chosen-avatar")
													//     : t("profile.avatars.choose")
												}

												{!userAvatar && t("buttons.buy")}
											</Button>
										</div>
									)
								})()}
						</>
					)
				})}
			</div>
			<BuyAvatarModal
				open={isBuyModalOpen}
				onClose={() => setIsBuyModalOpen(false)}
				name={selectedAvatar?.name || ""}
				price={selectedAvatar?.price || 0}
				handleBuy={handleBuy}
			/>
		</div>
	)
}
