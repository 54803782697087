import { WalletProvider } from "../../constants/walletProviders"
import { IUserPet } from "../../pages/Pets/types"
import { PointActions } from "../actionPoints/types"

export interface Profile {
	id: string
	userId: string
	username: string
	biography: string
}

export interface Level {
	id: string
	value: number
	pointsToReach: number
	platformFee: number
	maxAllocation: number
}

export enum SocialName {
	Telegram = "Telegram",
	Twitter = "Twitter",
	Discord = "Discord",
}

export interface Social {
	id: string
	name: SocialName | "Email"
	iconUrl: string
}

export interface SocialData {
	id: number
	firstName: string
	username: string
}

export interface LevelData {
	id: string
	progress: number
	userId: string
	currentLevelId: string
	currentLevel?: Level
	nextLevelPoints?: Level
	referralEarnedPoints?: number
	actionLevelPoints: Array<{
		action: PointActions
		id: string
		points: number | null
		tickets: number | null
	}>
}

export interface Kyc {
	id: string
	status: string
	sessionId: string
}

export interface ConnectedWallet {
	id: string
	address: string
	profile_id: string
	created_at: string
	provider: WalletProvider
}

export interface ConnectedSocials {
	id: string
	socialId: string
	social: Social
	socialData: SocialData
	createdAt: string
}

export interface IProfileAvatar {
	id: string
	avatarId: string
	avatar: {
		id: string
		name: string
		imageUrl: string
		iconUrl: string
		description: string
		descriptionLocalizationKey: string
	}
}

export interface UserSpendablePointsType {
	id: string
	amount: number
	updatedAt: string
}

export interface UserPrivacySettings {
	showDiscord: boolean
	showEmail: boolean
	showTelegram: boolean
	showTwitter: boolean
}

export interface UserData {
	id: string
	email: string
	isKol: boolean
	profile: Profile
	levelData: LevelData
	connectedWallets: ConnectedWallet[]
	referralCode: string
	kyc?: Kyc
	spendablePoints: UserSpendablePointsType
	profileSetupProgress: number
	connectedSocials: ConnectedSocials[]
	totalTicketsAmount: number
	avatar: IProfileAvatar
	pet?: IUserPet
	privacySettings: UserPrivacySettings
	hasReferrer: boolean
}

export interface UserReaction {
	id: string
	iconUrl: string
	reactionsCount: number
	reacted: boolean
}

export interface UserReaction {
	id: string
	iconUrl: string
	reactionsCount: number
	reacted: boolean
}

export interface IUserStore {
	user: UserData | null
	loading: boolean
	error: boolean
	socialError: string
	reactions: UserReaction[]
}

export interface IEditAvatarPayload {
	updateProfileOptions: {
		username: string
		biography: string
	}
	updateUserOptions: {
		email: string
		isKol: boolean
		role?: "USER"
	}

	updatePrivacySettingsOptions: {
		[key in keyof UserPrivacySettings]: boolean
	}
}

export interface SocialsType {
	id: string
	socialId: string
	social: {
		id: string
		name: string
		iconUrl: string
	}
	socialData: unknown
	createdAt: string
}
