import { useTranslation } from "react-i18next"
import { NFT_RARITY_DICT } from "../../../../constants/nft"
import { NFTRarity, RarityType } from "../../types"
import css from "./RarityChip.module.scss"
import { useMemo } from "react"

type IRarityChipProps = {
	rarity: RarityType
	className?: string
	size?: "regular" | "large"
}

export const RarityChip: React.FC<IRarityChipProps> = ({ rarity, className, size = "regular" }) => {
	const { t } = useTranslation()
	const raritySplitted = useMemo(() => {
		switch (rarity.split("_")[1]) {
			case "common":
				return NFTRarity.common
			case "epic":
				return NFTRarity.epic
			case "legendary":
				return NFTRarity.legendary
			case "rare":
				return NFTRarity.rare
			case "uncommon":
				return NFTRarity.uncommon
			default:
				return NFTRarity.common
		}
	}, [rarity])
	return (
		<div
			className={`${css.rarity} ${className} ${css[size]}`}
			style={{ backgroundColor: NFT_RARITY_DICT[raritySplitted].color }}
		>
			{t(NFT_RARITY_DICT[raritySplitted].titleI18key)}
		</div>
	)
}
