import React from "react";

import { Trans, useTranslation } from "react-i18next";

import { HistoryTypes } from "../StackingHistoryModal";
import { PurpleStackingItemGradient } from "../../../../../assets/iconComponents/PurpleStackingItemGradient";
import { StackingTimer } from "./StackingTimer";
import { HistoryWaitingGradient } from "../../../../../assets/iconComponents/HistoryWaitingGradient";
import Vincent from "../../../../../assets/images/vincent.png";
import ChestCut from "../../../../../assets/images/chest-cut.png";

import cn from "classnames";
import { getUTCDate } from "../../../../../utils/formatDate";

import css from "../styles.module.scss";

export const HistoryCard = ({
  setSubModalOpen,
  ticketsAmount,
  ...history
}: Omit<HistoryTypes, "isWaiting"> & {
  setSubModalOpen: (c: boolean) => void,
  ticketsAmount: number
}) => {
  const { t } = useTranslation()
  const isWaiting = new Date().getTime() < history.lotteryHeldDate.getTime()
  return (
    <div className={cn(css.stakeCardWrapper, css.stakeCardWrapperHistory)}>
      {isWaiting ? (
        <PurpleStackingItemGradient className={css.itemGradient} />
      ) : (
        <HistoryWaitingGradient className={css.itemGradientBlue} />
      )}
      {!history.isWinner && !isWaiting && (
        <img src={Vincent} className={css.looseImg} alt="vincent" />
      )}
      {history.isWinner && !isWaiting && (
        <img src={ChestCut} className={css.winnerImg} alt="vincent" />
      )}
      <header className={css.historyCardHeader}>
        <span
          className={cn(
            css.currentRaffle,
            !isWaiting && css.currentRaffleEnded,
          )}
        >
          {isWaiting ? (
            t("staking.history.currentRuffle")
          ) : (
            <>
              {t("staking.era.neolithic")} <span className={css.eraNum}>#{history.epochIndex}</span>
            </>
          )}
        </span>
        <span className={css.utcDate}>
          {getUTCDate(history.lotteryHeldDate)}
        </span>
      </header>
      {isWaiting ? (
        <div className={css.waitingInfo}>
          <div className={css.waitingText}>
            <span className={css.eraText}>{t("staking.era.neolithic")}</span>
            <span className={css.eraNum}>#{history.epochIndex}</span>
          </div>
          {history.lotteryHeldDate && <StackingTimer
            date={history.lotteryHeldDate.getTime()}
            untilPenaltyDate={+history.lotteryHeldDate.getTime()}
            isHistoryMode={true}
          />}
        </div>
      ) : !history.isWinner ? (
        <div className={css.loserInfo}>
          <div className={css.infoText}>
            <Trans
              i18nKey={"staking.history.lose.title"}
              components={{
                white: <span />,
                gray: <span />,
                br: <br />
              }}
            />
          </div>
          <div className={css.infoSubText}>
            {/* <Trans
              i18nKey={"staking.history.lose.text"}
              values={{ tickets: ticketsAmount }}
              components={{
                b: <b />,
              }}
            /> */}
          </div>
        </div>
      ) : (
        <div className={css.winnerInfo}>
          <div className={css.infoText}>
            <Trans
              i18nKey={"staking.history.won.title"}
              components={{
                white: <span />,
                gray: <span />,
                br: <br />
              }}
            />
          </div>
          <div className={css.infoSubText}>
            {/* <Trans
              i18nKey={"staking.history.won.text"}
              values={{ tickets: ticketsAmount }}
              components={{
                b: <b />,
              }}
            /> */}
            {/* You had 100 Entries and entrie <b>#91</b> wins this raffle. */}
          </div>
          <button
            className={css.stakeBtnPurple}
            // onClick={() => setSubModalOpen(true)}
            disabled
          >
            {t("staking.history.claim")}
          </button>
        </div>
      )}
    </div>
  );
};
