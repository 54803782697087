import { Cell, toNano } from "@ton/ton";
import { SendTransactionRequest, TonConnectUI } from "@tonconnect/ui-react";

export const nftClaim = async (
  price: string,
  nftCollectionAddress: string,
  signature: string,
  tonConnectUI: TonConnectUI
) => {
  try {
    const cell = Cell.fromBoc(Buffer.from(signature, "hex"))[0];
    const tx: SendTransactionRequest = {
      messages: [
        {
          amount: toNano(price).toString(),
          address: nftCollectionAddress,
          payload: cell.toBoc().toString("base64"),
        },
      ],
      validUntil: Math.round(Date.now() / 1000) + 3600,
    };

    return await tonConnectUI.sendTransaction(tx);
  } catch (error) {
    // setIsError(error as string)
    return null
  }
}
