import css from "../SelectedPetInfo/SelectedPetInfo.module.scss";
import { IPet } from "../../types";
import { useTimer } from "../../../../hooks/useTimer";
import { useTranslation } from "react-i18next";

import { oneDayInMs } from "../../../../constants/time";
import { getNextEvolutionAvailability } from "../../helpers/getNextEvolutionAvailability";
import { usePetsStore } from "../../store/petsStore";
import { Loader } from "../../../../components/Loader";
import { Button } from "../../../../components/Buttons/Button";
import { ArrowUpIcon } from "../../../../assets/iconComponents/new_design_icons/ArrowUp";

export const LvlUpButton = ({
  onClick,
  isBought,
  loading,
  selectedPet,
  currentPet,
}: {
  onClick: () => void;
  isBought: boolean;
  loading: boolean;
  selectedPet: IPet;
  currentPet: IPet;
}) => {
  const { t } = useTranslation();
  const { isLevelUpLoading } = usePetsStore();

  const isEvolutionAvailable = getNextEvolutionAvailability(currentPet);

  const lastLeveledUp = currentPet.userPet?.levelUppedAt || "0";

  const [{ hour, minute, seconds }, isFinished] = useTimer(
    new Date(new Date(lastLeveledUp).getTime() + oneDayInMs)
  );

  const isLevelUpDisabled =
    !isBought ||
    loading ||
    (!isEvolutionAvailable && !selectedPet.userPet?.nextLevelPet) ||
    isLevelUpLoading;

  return isFinished || isEvolutionAvailable ? (
    <Button
      className={css.btnRight}
      disabled={isLevelUpDisabled}
      onClick={onClick}
      variant="green"
      decorationBefore={<ArrowUpIcon color="#000000" />}
      decorationAfter={<ArrowUpIcon color="#000000" />}
      size="large"
      isUppercased
    >
      {!isLevelUpLoading && t("buttons.level")}
      {isLevelUpLoading && <Loader />}
    </Button>
  ) : (
    <Button
      className={css.btnRight}
      variant="secondary"
      disabled={!isEvolutionAvailable && !selectedPet.userPet?.nextLevelPet}
      size="large"
      isUppercased
    >
      {hour}h {minute}m {seconds}s
    </Button>
  );
};
