import React from "react"

import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react"
import { useTranslation } from "react-i18next"

import { Button } from "../../../../../components/Buttons/Button"
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader"
import { RarityChip } from "../../ClaimedEarly/RarityChip"
import { NorthShine } from "../NftCongratsModal/NorthShine"

import { charactersService } from "../../../service"
import { charactersStore } from "../../../store"
import { nftClaim } from "../../../utils"

import { ClaimStatus, NftPropsRaw } from "../../../types"

import { NFT_MULTICLAIM_CONTRACT_ADDRESS } from "../../../constants"

import css from "./NftInfoModal.module.scss"

type INftInfoModalProps = {
	open: boolean
	onClose: () => void
	nftInfo: Partial<NftPropsRaw>
}

export const NftInfoModal: React.FC<INftInfoModalProps> = ({ open, onClose, nftInfo }) => {
	const { t } = useTranslation()
	const { selectedNftToShow, selectedCollection, actions } = charactersStore()
	const [tonConnectUI] = useTonConnectUI()
	const wallet = useTonAddress()

	const isCalimDisabled = nftInfo.claimStatus !== ClaimStatus.started
	const handleClick = async () => {
		if (nftInfo.id) {
			const response = await charactersService.generateSignature([nftInfo.id])
			if (response && NFT_MULTICLAIM_CONTRACT_ADDRESS) {
				const { price, signature } = response
				const claimResponse = await nftClaim(price, NFT_MULTICLAIM_CONTRACT_ADDRESS, signature, tonConnectUI)

				if (claimResponse) {
					await charactersService.setClaimStatusToProcessing(wallet, [nftInfo.id])
					actions.setIsAbleToContinueClaim(true)
					actions.setIsNftCongratsModalOpen(true)
					actions.setIsAbleToNewClaim(false)
				}
			}
		}
	}

	return (
		<ModalWithHeader open={open} title={`#${nftInfo.index || "??????"}`} onClose={onClose} bodyClassName={css.wrapper}>
			<>
				<h3 className={css.title}>{selectedCollection?.collection.name}</h3>
				<div className={css.nft}>
					{nftInfo?.claimStatus === "PROCESSING" ? <NorthShine /> : null}
					<img src={nftInfo.url} alt="nft" className={nftInfo?.claimStatus === "STARTED" ? "" : css.bordered} />
					{nftInfo?.rarity ? <RarityChip rarity={nftInfo.rarity} size="large" /> : <div className={css.empty} />}
				</div>
				{selectedNftToShow?.claimStatus === ClaimStatus.started ? (
					<Button onClick={handleClick} disabled={isCalimDisabled} size="large" variant="secondary">
						{t("claim.characters.claim.button.claim")}
					</Button>
				) : null}
			</>
		</ModalWithHeader>
	)
}
