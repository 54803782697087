import React, { FormEvent, useState } from 'react';
import { LoginHeader } from "../LoginHeader/LoginHeader";
import { useTranslation } from "react-i18next";
import { InputField, InputFieldErrors } from "../../../../newComponents/Input";
import { CheckboxField } from "../../../../newComponents/CheckboxField";
import './LoginStep.scss';
import { LoginSocial } from "../LoginSocial/LoginSocial";
import { useLoginStore } from "../../store/loginStore";
import loginService from "../../services/loginService";
import { Btn } from "../../../../newComponents/Btn";
import accountLinkingService from "../../services/accountLinkingService";

export const LoginStep1 = () => {
  const { t } = useTranslation();

  const {
    email,
    isOTPRequestPending,
    emailErrorMessage
  } = useLoginStore();

  const [isUserAgreed, setIsUserAgreed] = useState(false);

  const onEmailChange = (value: string) => {
    if (emailErrorMessage) {
      loginService.clearEmailError();
    }

    loginService.setEmail(value);
  }

  const onSubmitEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isEmailExists = await accountLinkingService.checkIfEmailAccountExist(email)
    if (isEmailExists) {
      await loginService.sendOtpRequest(email);
    } else {
      accountLinkingService.setIsSignUpTypeModalOpen(true)
    }
  };

  let emailInputStatus: InputFieldErrors | null = null;
  let emailInputMessage: string | null = null;

  if (emailErrorMessage) {
    emailInputStatus = InputFieldErrors.ERROR;
    emailInputMessage = emailErrorMessage;
  }

  return (
    <section className="login-step">
      <LoginHeader
        title={t("auth.signup.title")}
        text={t("auth.signup.text")}
      />

      <form
        id='login-form'
        onSubmit={onSubmitEmail}
        className="login-step__body"
      >
        <InputField
          label={t("auth.signup.input.label")}
          type="email"
          placeholder={t("login.email")}
          onChange={onEmailChange}
          status={emailInputStatus}
          message={emailInputMessage}
        />

        <CheckboxField
          id="agree"
          name="agree"
          onChange={setIsUserAgreed}
        >
          {t("login.terms")}{" "}

          <a
            href="https://pixelverse.xyz/terms"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('login.conditions')}
          </a>
        </CheckboxField>
      </form>

      <footer className="login-step__footer">
        <Btn
          form="login-form"
          type="submit"
          isFullWidth
          disabled={!email || !isUserAgreed}
          isLoading={isOTPRequestPending}
        >
          {t("login.sign-in")}
        </Btn>

        <span className="login-step__or">
          <span>{t("auth.signup.footer.or")}</span>
        </span>

        <LoginSocial isUserAgreed={isUserAgreed} />
      </footer>
    </section>
  );
};
