import { ClaimedEarly } from "./components/ClaimedEarly"
import NftCollectionsList from "./components/NftCollectionsList"

import css from "./CharactersPage.module.scss"

export const CharactersPage = () => {
	return (
		<div className={css.wrapper}>
			<NftCollectionsList />
			<ClaimedEarly />
		</div>
	)
}
